.spinner_overlay {
  position: fixed;
  left: 0;
  top: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(221, 220, 220);
  opacity: 0.6;
  z-index: 1000;
}
.react-spinner-material{
  margin-left: 46%;
  margin-top: 24%;
  border-width: 10px;
}

small-loader{
  position: relative;
  margin: auto;
  background-color: rgb(221, 220, 220);
  opacity: 0.6;
}