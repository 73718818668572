#register-container{  
  display: flex;
  justify-content: center;
  height: 100%;
  background-image: url(../../assets/img/curve_web.png);
  background-repeat: no-repeat;
  background-size: 100% 50%;
  background-position: bottom;
  position: relative;
}

.condition-text{
  margin-top: 40px;
  height: 19px;
  color: #3F4045;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.12px;
  line-height: 16px;
}