@font-face {
  font-family: "Beautiful People Personal Use";
  src: url("../fonts/BeautifulPeople.ttf") format("truetype");
}
.BeautifulPeople {
  font-family: "Beautiful People Personal Use";
}

@font-face {
  font-family: "Beauty Mountains Personal Use";
  src: url("../fonts/BeautyMountains.ttf") format("truetype");
}

.BeautyMountains {
  font-family: "Beauty Mountains Personal Use";
}

@font-face {
  font-family: "Bunch Blossoms Personal Use";
  src: url("../fonts/BunchBlossoms.ttf") format("truetype");
}

.BunchBlossoms {
  font-family: "Bunch Blossoms Personal Use";
}
@font-face {
  font-family: "Country side Personal Use";
  src: url("../fonts/Countryside.ttf") format("truetype");
}

.Countryside {
  font-family: "Country side Personal Use";
}

@font-face {
  font-family: "Grestal Script Personal Use";
  src: url("../fonts/GrestalScript.ttf") format("truetype");
}

.GrestalScript {
  font-family: "Grestal Script Personal Use";
}

@font-face {
  font-family: "Lemon Jelly Personal Use";
  src: url("../fonts/LemonJelly.ttf") format("truetype");
}

.LemonJelly {
  font-family: "Lemon Jelly Personal Use";
}

@font-face {
  font-family: "Quick Kiss Personal Use";
  src: url("../fonts/QuickKiss.ttf") format("truetype");
}

.QuickKiss {
  font-family: "Quick Kiss Personal Use";
}
@font-face {
  font-family: "Alex Brush";
  src: url("../fonts/AlexBrush-Regular.ttf") format("truetype");
}
.AlexBrush {
  font-family: "Alex Brush";
}
@font-face {
  font-family: "Kaushan Script";
  src: url("../fonts/KaushanScript-Regular.otf") format("opentype");
}
.KaushanScript {
  font-family: "Kaushan Script";
}
@font-face {
  font-family: "Edmunds";
  src: url("../fonts/edmunds.otf") format("opentype");
}
.Edmunds {
  font-family: "Edmunds";
}
@font-face {
  font-family: "Edmunds Distressed";
  src: url("../fonts/edmunds_distressed.otf") format("opentype");
}
.EdmundsDistressed {
  font-family: "Edmunds Distressed";
}
@font-face {
  font-family: "Pacifico";
  src: url("../fonts/Pacifico.ttf") format("truetype");
}
.Pacifico {
  font-family: "Pacifico";
}
@font-face {
  font-family: "Seaside ResortNF";
  src: url("../fonts/SEASRN__.ttf") format("truetype");
}
.SeasideResortNF {
  font-family: "SeasideResortNF";
}
@font-face {
  font-family: "Sofia";
  src: url("../fonts/Sofia-Regular.otf") format("opentype");
}
.Sofia {
  font-family: "Sofia";
}
@font-face {
  font-family: "CooperHewitt";
  src: url("../fonts/cooper-hewitt/CooperHewitt-Book.otf") format("opentype");
}
.CooperHewitt {
  font-family: "CooperHewitt";
}
@font-face {
  font-family: "CraftInk";
  src: url("../fonts/CraftInk.otf") format("opentype");
}
.CraftInk {
  font-family: "CraftInk";
}
@font-face {
  font-family: "DeepColor";
  src: url("../fonts/DeepColor.otf") format("opentype");
}
.DeepColor {
  font-family: "DeepColor";
}
@font-face {
  font-family: "FunkyWaving";
  src: url("../fonts/FunkyWaving.otf") format("opentype");
}
.FunkyWaving {
  font-family: "FunkyWaving";
}
@font-face {
  font-family: "GreenSlush";
  src: url("../fonts/GreenSlush.otf") format("opentype");
}
.GreenSlush {
  font-family: "GreenSlush";
}
@font-face {
  font-family: "HappyVibes";
  src: url("../fonts/HappyVibes.otf") format("opentype");
}
.HappyVibes {
  font-family: "HappyVibes";
}
@font-face {
  font-family: "IndieToon";
  src: url("../fonts/IndieToon.otf") format("opentype");
}
.IndieToon {
  font-family: "IndieToon";
}
@font-face {
  font-family: "Kapiler";
  src: url("../fonts/Kapiler.ttf") format("truetype");
}
.Kapiler {
  font-family: "Kapiler";
}
@font-face {
  font-family: "Lavaflows";
  src: url("../fonts/Lavaflows.otf") format("opentype");
}
.Lavaflows {
  font-family: "Lavaflows";
}
@font-face {
  font-family: "LeafyTropica";
  src: url("../fonts/LeafyTropica.otf") format("opentype");
}
.LeafyTropica {
  font-family: "LeafyTropica";
}
@font-face {
  font-family: "LineTone";
  src: url("../fonts/LineTone.otf") format("opentype");
}
.LineTone {
  font-family: "LineTone";
}
@font-face {
  font-family: "NeonBulb";
  src: url("../fonts/NeonBulb.otf") format("opentype");
}
.NeonBulb {
  font-family: "NeonBulb";
}
@font-face {
  font-family: "NightMaze";
  src: url("../fonts/NightMaze.otf") format("opentype");
}
.NightMaze {
  font-family: "NightMaze";
}
@font-face {
  font-family: "Poesia";
  src: url("../fonts/Poesia.otf") format("opentype");
}
.Poesia {
  font-family: "Poesia";
}
@font-face {
  font-family: "PunkSteamer";
  src: url("../fonts/PunkSteamer.otf") format("opentype");
}
.PunkSteamer {
  font-family: "PunkSteamer";
}
@font-face {
  font-family: "Rubrika";
  src: url("../fonts/Rubrika.ttf") format("truetype");
}
.Rubrika {
  font-family: "Rubrika";
}
@font-face {
  font-family: "Shoelacy";
  src: url("../fonts/Shoelacy.otf") format("opentype");
}
.Shoelacy {
  font-family: "Shoelacy";
}
@font-face {
  font-family: "StickySlime";
  src: url("../fonts/StickySlime.otf") format("opentype");
}
.StickySlime {
  font-family: "StickySlime";
}
@font-face {
  font-family: "StripedSweet";
  src: url("../fonts/StripedSweet.otf") format("opentype");
}
.StripedSweet {
  font-family: "StripedSweet";
}
@font-face {
  font-family: "TropicalRain";
  src: url("../fonts/TropicalRain.otf") format("opentype");
}
.TropicalRain {
  font-family: "TropicalRain";
}
@font-face {
  font-family: "Wezen";
  src: url("../fonts/Wezen.otf") format("opentype");
}
.Wezen {
  font-family: "Wezen";
}
@font-face {
  font-family: "WildMeadow";
  src: url("../fonts/WildMeadow.otf") format("opentype");
}
.WildMeadow {
  font-family: "WildMeadow";
}