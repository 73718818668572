#profile-container{
    padding-left: 40px;
    background-color: #FFFFFF;
    padding-bottom: 100px;
}

.general-info{
    height:'70px';
}

.g-i-heading{
    margin: 35px 0px 8px auto ;
    height: 15px;
    color: #5F6165;
    font-family: Roboto;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
}

.g-i-divider{
    box-sizing: border-box;
    height: 1px;
    width: 440px;
    border: 0.7px solid #D9DFE2;
}

.g-i-edit-block{
    height: 20px;
  width: 49px;
  margin-left: 300px;
}
.g-i-edit{
    height: 20px;
  color: #5F6165;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: -0.03px;
  line-height: 19.6px;
}

.g-i-edit-controls{
  margin-left: 200px;
}
.g-i-discard-btn{
  height: 24px;
  width: 60px;
  align-items: center;
  border-radius: 5px;
  background-color: #EDEEF0;
}

.g-i-save-btn{
  height: 24px;
  width: 60px;
  text-align: center;
  margin-left: 10px;
  border-radius: 5px;
  background-color: #0FB269
}

.g-i-discard-text{
  height: 26px;
  width: 42px;
  margin: 5px 8px;
  color: #6A6976;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
}

.g-i-save-text{
  height: 26px;
  width: 42px;
  margin: 5px 10px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
}

.g-i-profile-info{
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.g-i-profile-pic{
  box-sizing: border-box;
  height: 107px;
  width: 107px;
  border: 1px solid #979797;
  border-radius: 15px;
  margin-right: 30px;
}

.g-i-profile-name{
  height: 20px;
  color: #3F4045;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 19.6px;
}

.g-i-profile-email{
  height: 19px;
  color: #808A8F;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}

.g-i-input-details-block{
  padding-top: 58px;
  width:600px;
  display: flex;
  flex-flow: row wrap;
}
.g-i-col-2{
  margin-left:20px
}

.g-i-input-details-label{
  height: 15px;
  color: #5F6165;
  margin-bottom: 5px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
}

.g-i-input-details-input:disabled{
  box-sizing: border-box;
  margin-bottom: 15px;
  height: 38px;
  width: 210px;
  border: 1px solid #DBDDDE;
  border-radius: 6px;
  background-color: white;
  padding-left: 10px;
}
.g-i-input-details-input{
  box-sizing: border-box;
  margin-bottom: 15px;
  padding-left: 10px;
  height: 38px;
  width: 210px;
  border: 1px solid #DBDDDE;
  border-radius: 6px;
  background-color: #F2F4F5;
  font-family: Roboto;
  font-size: 14px;
}

.g-i-input-details-input::placeholder{
  height: 19px;
  width: 152px;
  color: #5F6165;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  padding-left: 10px;
}
.r-p-heading{
  height: 15px;
  width: 104px;
  color: #3F4045;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 15px;
}

.r-p-heading{
  margin-top: 40px;
  margin-bottom: 30px;
}