#liveview-container {
  /* padding: 19px 32px; */
}
.l-v-h {
  height: 17px;
  color: #808a8f;
  font-size: 12.41px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: uppercase;
}
.l-v-f {
  height: 31.43px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 8px 50px -4px rgba(0, 0, 0, 0.05);
}
.l-v-s-o-box {
  height: 123.24px;
  border-radius: 12.32px;
  background-color: #ffffff;
  margin: 0px 10px 10px 0;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 6%);
}
.l-v-chart-box {
  box-sizing: border-box;
  height: 397.12px;
  border: 0.88px solid #eaf0f7;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 9px -9px rgba(0, 0, 0, 0.2);
  margin: 0px 10px 10px 0;
  padding: 22px;
}
.l-v-i-t-h {
  height: 15px;
  color: #9191af;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  text-transform: uppercase;
  margin-top: 63px;
  margin-bottom: 24px;
}
.l-v-i-t-box {
  height: 150px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(95, 100, 112, 0.38);
  margin: 0px 10px 10px 0;
  cursor: pointer;
}
.l-v-a-c-h {
  height: 15px;
  color: #9191af;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 27px;
  margin-top: 57px;
}

.l-v-i-t-box-t {
  height: 50%;
}
.l-v-i-t-box-b {
  height: 50%;
  border-top: 0.5px dashed var(--dashedBorderColor);
  opacity: 0.76;
  display: flex;
  align-items: center;
  justify-content: center;
}
.l-v-i-t-box-b-btn {
  height: 38px;
  width: 175px;
  border-radius: 19px;
  background-color: var(--buttonBg);
  color: var(--whiteBg);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.l-v-i-t-box-t-c {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.l-v-i-t-box-t-c canvas {
  width: 50px !important;
  height: 50px !important;
}
.l-v-i-t-box-t-h {
  height: 30px;
  color: #212145;
  font-size: 23.11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
}
.l-v-i-t-box-t-h-c {
  height: 14px;
  color: #0fb269;
  font-size: 10.78px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  padding-left: 9px;
}
.l-v-i-t-box-t-desc {
  height: 13px;
  color: #808a8f;
  font-size: 9.24px;
  letter-spacing: 0;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
}
.c-orange {
  color: #e35d25 !important;
}

.l-v-s-o-box-t {
  height: 60%;
  padding: 0 18px;
}
.l-v-s-o-box-b {
  height: 40%;
}
.l-v-s-o-box-t-h {
  height: 14px;
  color: #9191af;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 13px;
}
.l-v-s-o-box-t-c {
  height: 31px;
  color: #212145;
  font-size: 23.11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
}
.l-v-s-o-box-t-c-lbl {
  height: 14px;
  color: #9191af;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 13px;
  text-transform: uppercase;
}
.l-v-s-o-box-t-c-per {
  height: 15px;
  color: #0fb269;
  font-size: 10.78px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  text-align: right;
}
.l-v-s-o-box-t-c-per-lbl {
  height: 14px;
  color: #9191af;
  font-size: 9.24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 13px;
  text-align: right;
}
.l-v-s-o-box-b canvas {
  height: 42px !important;
  padding: 0 20px;
}

.recentOne {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.recentOne2 {
  display: flex;
  flex-direction: column;
  /* margin-right: 2px; */
  /* align-items: center; */
}

.fontStyle {
  font-size: 70px;
  margin: 113px 0px;
}
