.text-card {
  width: 130px;
  height: 63px;
  /* You can adjust the width as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(39, 39, 47);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #23232b;
  box-shadow: 3px 3px 0px 0px rgba(255, 255, 255, 0.11);
}

.card-img_text {
  width: 20%;
  max-height: 200px; /* Adjust the maximum height of the image */
  object-fit: cover;
  border-radius: 5px;
  background-color: rgb(39, 39, 47);
}

.text-store {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  background-color: rgb(39, 39, 47);
  max-height: max-content;
  overflow-y: clip;
  align-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.typography-store{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    background-color: rgb(39, 39, 47);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: #23232b;
}
