.boxtemplate {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  width: 300px;
  height: 610px;
  border-radius: 15px;
  background: #27272f;
  border: 1px solid #27272f;
  margin-top: 10px;
  margin-left: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.boxtemplate::-webkit-scrollbar {
  display: none;
}

.image-store::-webkit-scrollbar {
  display: none;
}

.templatecontainer {
  min-width: 84px;
  width: 84px;
  height: 128.348px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(39, 39, 47);
  max-height: 130px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.20);
}

.card-template {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.bigLayout {
  min-width: 47%;
  /* width: 47%; */
  justify-content: center;
  text-align: center;
}

.singleLayout {
  min-width: 100%;
  /* width: 47%; */
  justify-content: center;
  text-align: center;
}

.card-img {
  width: 20%;
  max-height: 200px; /* Adjust the maximum height of the image */
  object-fit: cover;
  border-radius: 5px;
  background-color: rgb(39, 39, 47);
}

.typography-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
} 

.typography-card {
  width: 80%;
  word-wrap: break-word;
  margin-bottom: 0px;
  font-size: 26px;
} 

.image-store {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  background-color: rgb(39, 39, 47);
  overflow-x: scroll;
  max-height: max-content;
  overflow-y: clip;
  align-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.expanded {
  flex-wrap: wrap;
  /* justify-content: center; */
  overflow-x: hidden;
}

.audioControl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding-top: 17px;
}

input {
  color: "#fff" !;
  background-color: "#27272f";
  border-color: "#484851";
  box-shadow: "none";
}

input:focus {
  border-color: "#555";
}

.view-more {
  border: 1px solid rgb(72, 72, 81);
  padding: 5px;
  border-radius: 10px;
}

.view-more:hover {
  border: 1px solid rgb(72, 72, 81);
  background-color: rgb(72, 72, 81);
  padding: 5px;
  border-radius: 10px;
}

.appcontainer {
  min-width: 76.824px;
  width: 76.824px;
  height: 76.82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(39, 39, 47);
  max-height: 130px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.20);
}

.app-card {
  width: 76.824px;
  height: 76.82px;
  border-radius: 10px;
}
