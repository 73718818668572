.generate_ai_button{
    border-radius: 10px;
    border: 1px solid #484851;
    border-radius: 10px;
    border: 1px solid #484851;
    background-color: "#017EFA";
    padding: 4px;
    cursor: pointer;
    color: #fff;
    width: 300px;
    align-items: center;
    text-align: center;
  }

  .css-1uccc91-singleValue{
    color: #fff;
  }