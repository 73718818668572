input[type="button"] {
    cursor: pointer;
    outline: none;
}
.h-100 {height: 100% !important}
.w-100 {width: 100% !important}
.w-99 {width: 99% !important}
.w-97 {width: 97% !important}
.w-95 {width: 95% !important}
.w-90 {width: 90% !important}
.w-85 {width: 85% !important}
.w-83 {width: 83% !important}
.w-80 {width: 80% !important}
.w-78 {width: 78% !important}
.w-70 {width: 70% !important}
.w-67 {width: 67% !important}
.w-60 {width: 60% !important}
.w-65 {width: 65% !important}
.w-63 {width: 63% !important}
.w-55 {width: 55% !important}
.w-50 {width: 50% !important}
.w-48 {width: 48% !important}
.w-45 {width: 45% !important}
.w-40 {width: 40% !important}
.w-35 {width: 35% !important}
.w-34 {width: 34% !important}
.w-33 {width: 33% !important}
.w-32 {width: 32% !important}
.w-31 {width: 31% !important}
.w-30 {width: 30% !important}
.w-25 {width: 25% !important}
.w-24 {width: 24% !important}
.w-23 {width: 23% !important}
.w-22 {width: 22% !important}
.w-20 {width: 20% !important}
.w-15 {width: 15% !important}
.w-12 {width: 12% !important}
.w-10 {width: 10% !important}
.w-8 {width: 8% !important}
.w-5 {width: 5% !important}
.f-12 {font-size: 12px !important;}
.f-14 {font-size: 14px !important;}
.f-16 {font-size: 16px !important;}
.f-22 {font-size: 22px !important;}
.f-40 {font-size: 40px !important;}
.p-r-10 {padding-right: 10px !important;}
.p-l-10 {padding-left: 10px !important;}
.p-r-5p {padding-right: 5% !important;}
.p-l-5p {padding-left: 5% !important;}
.m-r-10 {margin-right: 10px !important;}
.m-l-10 {margin-left: 10px !important;}
.text-bold {font-weight: bold !important;}
.text-u {text-transform: uppercase !important;}
.text-l {text-transform: lowercase !important;}
.text-c {text-transform: capitalize !important;}
.disp-inline { display: inline !important; }
.disp-inline-b { display: inline-block !important; }
.c-pointer {cursor: pointer !important; }
.disp-flex { display: flex !important; }
.disp-flex-root { display: flow-root !important; }
.c-pointer { cursor: pointer; }
.c-n-allowed { cursor: not-allowed; }
.m-0-auto { margin: 0 auto !important;}
.b-r-10 { border-radius: 10px !important; }
.bg-white { background: white !important;}
.p-relative { position: relative !important;}
.p-absolute { position: absolute !important;}
.p-fixed { position: fixed !important;}
.bg-white-box { 
    background: white !important;
    box-shadow: 0 1px 4px 0 rgb(30 36 39 / 30%);
}
.hidden{
    visibility: hidden;
}
.mvp-link { text-decoration: underline; cursor: pointer;}
.v-a-top {vertical-align: top !important;}
.v-a-t-top {vertical-align: text-top !important;}
.v-a-bottom {vertical-align: top !important;}
.v-a-t-bottom {vertical-align: text-bottom !important;}
img {cursor: pointer !important;}
.txt-green {color: #0FB269 !important;}
.txt-orange {color: #DCA640 !important;}
.txt-blue {color: #6B9CF3 !important;}
.txt-grey {color: #808A8F !important;}
button:disabled,
button[disabled]{
    opacity: 0.7;
    cursor: not-allowed;
}
.c-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.f-d-c {flex-direction: column;}
.f-d-r {flex-direction: row;}
.chart-container {
    box-sizing: border-box;
    border: 0.88px solid #EAF0F7;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 9px -9px rgb(0 0 0 / 20%);
}
.chart-heading {
    height: 21px;
    color: #67758D;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
}
.selection-error {
    color: #ce3e3e;
    font-weight: bold;
    font-size: 12px;;
}
.content-c {
    display: flex;
    align-items: center;
    justify-content: center;
}
.f-c-box {
    height: 21.43px;
    width: 130.31px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 50px -4px rgba(0,0,0,0.05);
    padding: 2px 10px 0 10px;
    cursor: pointer;
    margin-right: 12px;
}
.d-dp-lbl {
    height: 15px;
    color: #6A6976;
    font-size: 12.41px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    text-align: right;
}

/* for custom checkbox */
input[type=checkbox], input[type=radio] {
    --active: #275EFE;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 17px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    /* margin: 8px 0 0 10px; */
}
input[type=checkbox]:not(.switch) {
    border-radius: 50%;
}
input[type=checkbox]:not(.switch), input[type=radio]:not(.switch) {
    width: 17px;
}
input[type=checkbox]:not(.switch):checked {
    --r: 43deg;
}
input[type=checkbox]:not(.switch):checked, input[type=radio]:not(.switch):checked {
    --o: 1;
}
input[type=checkbox]:checked, input[type=radio]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
}
input[type=checkbox]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 5px;
    top: 2px;
    transform: rotate(var(--r, 20deg));
}
input[type=checkbox]:not(.switch):after, input[type=radio]:not(.switch):after {
    opacity: var(--o, 0);
}
input[type=checkbox]:after, input[type=radio]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.selectedBox {
    border: solid 1px var(--selectedBoxBorder) !important;
}

#manage-rewards-container header, #engagements-journey-container header, 
#e-s-table-sec header, #analytics-report-container header, 
#segments-container header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.rdt_Pagination, .rdt_TableBody {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.sc-hHftDr {
    margin-bottom: 0px !important;
}
.rdt_TableRow, .rdt_TableHeadRow {
    border: none !important;
}
.rdt_Pagination {
    border-top-style: unset !important;
}

@keyframes customEnterOverlayAnimation {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
}
@keyframes customLeaveOverlayAnimation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
}
  
@keyframes customEnterModalAnimation {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
}
@keyframes customLeaveModalAnimation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.2);
    }
}