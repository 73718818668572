#side-menu-container {
  /* background: var(--sideMenuBg); */
  margin: 20px 0 0 50px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2.63px 25.154px rgba(0, 0, 0, 0.05),
    inset 0px 0px 0.931631px rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(13.9745px);
  /* height: 100vh; */
  border-radius: 16px;
  min-width: 16%;
  overflow-y: scroll
}
.s-m-major,
.s-m-sub {
  height: inherit;
}
.h-logo-wrapper {
  display: flex;
  /* justify-content: center; */
  margin: 20px 0 20px 10px;
  align-items: center;
}
.h-logo {
  width: 100px;
  /* margin-left: 24px;
  margin-top: 12px; */
  cursor: pointer;
}
.s-m-item {
  /* height: 55px; */
  cursor: pointer;
  padding: 10px;
  color: #67748e;
  /* display: flex; */
  margin-left: 10px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  border-radius: 12px 0 0 12px;
}

.s-m-item-lbl {
  height: 18px;
  color: #3f4045;
  font-size: 13px;
  margin-bottom: 10px;
  letter-spacing: 0;
  line-height: 21px;
  padding-left: 10px;
}
.s-m-item-lbl.active {
  font-weight: bold;
  color: #017efa !important;
}
.s-m-item.active {
  font-weight: bold;
  color: #017efa !important;
  background: var(--sideMenuActiveBg);
}
.s-m-item-img {
  width: 18px;
  cursor: pointer;
}
.s-m-sub {
  background-color: var(--sideMenuSubBg);
}
.s-m-sub-h {
  padding: 14px 20px;
  color: #3f4045;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
}
.s-m-sub-tab {
  color: #727070;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 21px;
  font-weight: 400;
  padding: 5px 7px;
  cursor: pointer;
}
.s-m-sub-tab.active {
  font-weight: bold;
  color: #017efa;
  border-radius: 6px;
  background-color: #f2f9ff;
  margin: 5px 0;
}
.pill {
  color: green;
  border: 1px solid green;
  /* position: absolute;
    top: 0;
    right: 0; */
  font-size: 1px;
  padding: 5px;
  border-radius: 15px;
}

.sideMenueMain {
  position: absolute;
  width: 17%;
  height: 100%;
  left: 15px;
  top: 15px;
  z-index: 9999;
  background: rgb(255, 0, 76);
}
