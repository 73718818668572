.manage-journey-block{
    padding-bottom: 15px;
    display: inline-block;
}

.manage-journey{
    height: 21px;
    color: #3F4045;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
}

.manage-journey-text{
    height: 16px;
    color: #6A6976;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}

.journey-table-block{
    margin: 2px;
}

.btn-create-journey {
    height: 38px;
    width: 200px;
    border-radius: 4px;
    background-color: var(--buttonBg);
    cursor: pointer;
    display: inline-block;
}

.btn-c-j-text2 {
    height: 20px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}

.btn-c-j-text {
    height: 20px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}

.create-new-journey-container{
    height: 750px;
    /* border-radius: 10px; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38);
    padding: 20px 42px;
}

.heading-c-j{
    height: 21px;
    color: #3F4045;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 17px;
} 

.journey-name-c-j{
    opacity: 0.5;
    color: #5F6165;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    padding-bottom: 10px;

}

.input-field-c-j{
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
    padding: 12.5px 14.5px;
}

.input-field-c-j:hover{
    border: 1px solid #E3E7ED;
    border-radius: 4px;
}
.c-j-lbl-u-t {
    height: 15px;
    opacity: 0.5;
    color: #5F6165;
    /* font-family: Lato; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    margin: 10px 0;
}
.dragable-tasks {
    margin-bottom: 39px;
}
.dropped-tasks {
    margin-top: 21px;
}
.dd-h {
    height: 17px;
    color: #6B9CF3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
}
.u-t-box {
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #DBDDDE;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.17);
    padding: 7px 11px;
    margin-bottom: 10px;
    margin-right: 5px;
    cursor: move;
}
.u-t-box-h {
    height: 17px;
    color: #5F6165;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
}
.u-t-box img { 
    float: right;
    padding: 5px 0;
}
.u-a-t-drop-template {
    box-sizing: border-box;
    height: 75px;
    border: 1px dashed #CED3D6;
    border-radius: 4px;
    background-color: #F8F9F9;
    margin-bottom: 14px;
    padding-top: 20px;
}
.u-t-dropped-box {
    box-sizing: border-box;
    height: 125px;
    border: 1px solid #6B9CF3;
    border-radius: 4px;
    background-color: rgba(107,156,243,0.1);
    padding: 9px;
    margin-bottom: 14px;
}
.u-t-dropped-box-h {
    height: 39px;
    border-radius: 4px;
    background-color: #6B9CF3;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.17);
    margin-bottom: 13px;
}
.u-t-dropped-box-h-lbl {
    height: 17px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    padding: 11px;
}
.u-t-dropped-box-sub-h {
    height: 12px;
    color: #5F6165;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 5px;
}

.u-a-t-drop-template-add {
    height: 15px;
    color: #5F6165;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
}
.u-a-t-drop-template-desc {
    height: 12px;
    color: #AEB4B7;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
}

.c-j-action-sec{
    height: 62px;     
}

/* came from master */

.c-j-a {
    height: 62px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38);
    display: flex;
    align-items: center;
}
.c-j-a-s {
    height: 38px;
    width: 89px;
    border-radius: 4px;
    background-color: #4ACB91;
    color: #ffffff;
    cursor: pointer;
}
.c-j-a-c {
    height: 38px;
    width: 89px;
    border-radius: 4px;
    cursor: pointer;
    color: #808A8F;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
}