.searchBar{
    box-sizing: border-box;
    height: 30px;
    width: 200px;
    border: 1px solid #DBDDDE;
    border-radius: 14.5px;
    background-color: #FFFFFF;
    opacity: 0.4;
    color: #000000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 12px;
    padding-left: 12px;
    padding-right: 12px;
    border: none;
}
.s-b-left {
    box-sizing: border-box;
    height: 30px;
    /* width: 94px; */
    border: 1px solid #DBDDDE;
    border-radius: 19.5px 0 0 19.5px;
    background-color: #F2F4F5;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.s-b-right {
    box-sizing: border-box;
    height: 30px;
    width: 200px;
    border: 1px solid #DBDDDE;
    border-radius: 0 19.5px 19.5px 0;
    background-color: #FFFFFF;
}
.s-b-l-txt {
    height: 13px;
    color: #808A8F;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 13px;
}
.s-b-only-search {
    box-sizing: border-box;
    height: 30px;
    width: 200px;
    border: 1px solid #DBDDDE;
    border-radius: 19.5px;
    background-color: #FFFFFF;
}
.s-b-a-select {
    box-sizing: border-box;
    height: 21px;
    width: 114px;
    border: 1px solid #DBDDDE;
    border-radius: 10px;
    background-color: #F2F4F5;
    color: #3F4045;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
}