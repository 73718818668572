.e-s-tab {
  color: #5f6165;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.46px;
  line-height: 18px;
  padding: 10px 20px;
  display: inline-block;
  cursor: pointer;
  margin-right: 7px;
  background-color: #f2f2f2;
  border-radius: 8px;
}
.e-s-tab-active {
  border-radius: 8px;
  background-color: #1f2020;
  /* color: var(--buttonBg); */
  color: "white";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
}
.btn-create-engagement {
  height: 38px;
  width: 200px;
  border-radius: 4px;
  background-color: var(--buttonBg);
  cursor: pointer;
}
.btn-c-e-text {
  height: 20px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}
#c-s-action-sec {
  height: 100px;
  border-top: solid 1px #e8e8e8;
  padding: 14px 20px 0 0;
  background-color: var(--whiteBg);
  border-radius: 0 0 10px 10px;
}
.c-s-btn-approve {
  height: 38px;
  width: 89px;
  border-radius: 4px;
  background-color: var(--buttonBg);
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  border: none;
  outline: 0;
}
.c-s-btn-back {
  height: 38px;
  width: 89px;
  border-radius: 4px;
  background-color: var(--buttonAltBg);
  color: #385b69;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  outline: 0;
  border: none;
}
.c-s-breadcrum-back {
  height: 21px;
  color: #3f4045;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  cursor: pointer;
}
.c-s-breadcrum-title {
  height: 21px;
  color: #6a6976;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
.c-s-step-sec {
  height: 75px;
  border-radius: 10px 10px 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(95, 100, 112, 0.38);
}
.c-s-content-sec {
  /* border-radius: 0 0 10px 10px; */
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(95, 100, 112, 0.38);
}
.e-s-switch {
  color: var(--buttonBg);
}
#e-s-table-sec input[type="checkbox"] {
  border-radius: 15%;
}
.no-engagements {
  margin: 10%;
  display: flex;
  justify-content: center;
  color: #6a6fe5;
  font-size: 15px;
}

.segment {
  /* font-family: ; */
}
