#login-container{
  display: flex;
  justify-content: center;
  height: 100%;
  background-image: url(../../assets/img/curve_web.png);
  background-repeat: no-repeat;
  background-size: 100% 50%;
  background-position: bottom;
  position: relative;
}
.login-logo{
  position: absolute;
  left: 56px;
  top: 30px;
  height: 80px;
  width: 180px;
}
.login-outer-container{
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
    margin-top: 6%;
    margin-bottom: 2%;
}
.login-container{
    height: fit-content;
}
.reset-password-outer-container{
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  margin-top: 5%;
  margin-bottom: 10%;
}
.forgot-password-outer-container{
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  margin-top: 5%;
  margin-bottom: 4%;
}
.reset-password-container{
  height: auto;
  box-shadow: 0 2px 9px 0 rgba(40,44,51,0.26);
}

#g-recaptcha{
  margin-top: 2%;
  margin-left: 13%;
}


.LoaderButton .spinning {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}