.predictionDropDown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.summeryCount {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.chartAndData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  margin: 5px;
}
