.headerStyle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background-color: #e3e7ed;
    padding: 0px 16px 0px 16px;
    width: 100%;
}

.childRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background-color: white;
    padding: 0px 16px 0px 16px;
    width: 100%;

}

.headerTextStyle{
    font-size: 13px;
    font-weight: 500;
    color: #191D28;
    width: 16%;
}
.headerTextStyleA{
    font-size: 13px;
    font-weight: 500;
    color: #191D28;
    width: 14%;
}
.headerTextStyleName{
    font-size: 13px;
    font-weight: 500;
    color: #191D28;
    width: 22%;
}

.rowStyle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background-color:#f8f9fa;
    padding: 0px 16px 0px 16px;
    width: 100%;
}
.rowTextStyle{
    color: #6A6976;
    font-family: Roboto;
    font-size: 12px;
    word-break: break-word;
    width: 16%;
}

.rowTextStyleName{
    color: #6A6976;
    font-family: Roboto;
    font-size: 12px;
    word-break: break-word;
    width: 22%;
}

.childRowTextStyle{
    color: #6A6976;
    font-family: Roboto;
    font-size: 12px;
    word-break: break-word;

}


.btn-create-ab-row {
    height: 38px;
    width: 125px;
    border-radius: 4px;
    background-color: var(--buttonBg);
    cursor: pointer;
    padding-top: 6px;
    padding-left: 11px;

}
.btn-ab-text {
    height: 20px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}

.action{
    margin-right: -70px;
}
.columnElement{
display: flex;
flex-direction: row;
align-items: center;
width: 16%;
}

.percentageREd{
    font-size: 13px;
    margin-left: 13px;
    color: rgb(247, 6, 6);
}
.percentageGreen{
    font-size: 13px;
    margin-left: 13px;
    color:rgb(3, 146, 3)

}
