.box {
  height: 621px;
  width: 235px;
}

.box .group {
  height: 621px;
  left: 0;
  /* position: fixed; */
  top: 0;
  width: 100%;
}

.box .add-account {
  align-items: center;
  background-color: #27272f;
  border-radius: 16px;
  box-shadow: 0px 10px 32px #00000012;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 621px;
  overflow: hidden;
  padding: 15px;
  position: relative;
  width: 100%;
}

.box .div {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #484851;
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  overflow: hidden;
  padding: 5px 5px 5px 10px;
  position: relative;
  width: 100%;
}

.box .text-wrapper {
  color: #f2f2f5b3;
  font-family: "Rubik-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  outline: unset;
  border: 0;
  background: transparent;
}

.box .vuesax-bulk-search-wrapper {
  border: 1px solid;
  border-color: #484851;
  border-radius: 10px;
  height: 27px;
  position: relative;
  width: 28px;
}

.box .vuesax-bulk-search {
  height: 20px;
  left: 3px;
  position: absolute;
  top: 2px;
  width: 20px;
}

.box .add-account-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 15px;
  position: relative;
  width: 100%;
}

.box .add-account-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.box .text-wrapper-2 {
  color: #c0c0d6;
  font-family: "Rubik-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.box .eye-wrapper {
  align-items: center;
  border: 1px solid;
  border-color: #484851;
  border-radius: 10px;
  display: flex;
  gap: 44px;
  height: 30px;
  justify-content: center;
  padding: 8px 10px;
  position: relative;
  width: 30px;
}

.box .eye-instance {
  height: 20px !important;
  margin-bottom: -3px !important;
  margin-left: -5px !important;
  margin-right: -5px !important;
  margin-top: -3px !important;
  position: relative !important;
  width: 20px !important;
}

.box .add-account-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 15px;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.box .group-2 {
  align-self: stretch;
  background-size: 100% 100%;
  height: 84px;
  position: relative;
  width: 100%;
  border: 1px solid grey;
  border-radius: 10px;
}

.box .more-wrapper {
  align-items: center;
  background-color: #35373b;
  border-radius: 0px 0px 10px 0px;
  display: flex;
  gap: 10px;
  height: 35px;
  justify-content: center;
  left: 169px;
  padding: 10px;
  position: absolute;
  top: 49px;
  width: 35px;
}

.box .icon-instance-node {
  height: 20px !important;
  margin-bottom: -2.5px !important;
  margin-left: -2.5px !important;
  margin-right: -2.5px !important;
  margin-top: -2.5px !important;
  position: relative !important;
  width: 20px !important;
}

.box .eye-instance-wrapper {
  align-items: center;
  background-color: #35373b;
  border-radius: 10px 0px 0px 0px;
  display: flex;
  gap: 10px;
  height: 35px;
  justify-content: center;
  left: 134px;
  padding: 10px;
  position: absolute;
  top: 49px;
  width: 35px;
}

.box .social-media-logo-wrapper {
  align-items: center;
  background-color: #35373b;
  border-radius: 0px 10px 0px 10px;
  display: flex;
  gap: 10px;
  height: 35px;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: absolute;
  top: 49px;
  width: 35px;
}

.box .social-media-logo {
  height: 18px !important;
  margin-bottom: -1.5px !important;
  margin-left: -1.5px !important;
  margin-right: -1.5px !important;
  margin-top: -1.5px !important;
  position: relative !important;
  width: 18px !important;
}

.box .add-account-5 {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 7px;
  position: absolute;
  top: 10px;
  width: 191px;
}

.box .rectangle {
  height: 30px;
  position: relative;
  width: 30px;
}

.box .add-account-6 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.box .time {
  align-self: stretch;
  color: #ffffff;
  font-family: "Rubik-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.box .time-2 {
  align-self: stretch;
  color: #c0c0d6;
  font-family: "Rubik-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.box .group-3 {
  align-self: stretch;
  /* background-image: url(./image.svg); */
  background-size: 100% 100%;
  height: 84px;
  position: relative;
  width: 100%;
}

.box .add-account-7 {
  align-items: center;
  background-color: #35373b;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 30px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 10px;
  position: relative;
}

.box .add-instance {
  height: 20px !important;
  margin-bottom: -3px !important;
  margin-top: -3px !important;
  position: relative !important;
  width: 20px !important;
}

.box .text-wrapper-3 {
  color: #ffffff;
  font-family: "Rubik-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -0.5px;
  margin-top: -2.5px;
  position: relative;
  width: fit-content;
}

.box-first_new.post-files-container.add-account{
  width: 60%;
  display: flex;
  gap: 15px;
  margin-left: 27%;
  justify-content: center;
  align-items: center;
}
