.s-tabs {
    height: 63px;
    background-color: #dbdcdc;
}
.s-header {
    height: 24px;
    color: #3F4045;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 24px;
}
#settings-container input[type="text"], #settings-container input[type="password"], #settings-container select {
    padding: 6px 10px 6px 10px;
    opacity: 0.5;
    color: #5F6165;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
}
#settings-container textarea {
    padding: 8px 16px;
    opacity: 0.5;
    color: #5F6165;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
}
.s-close {
    height: 38px;
    width: 38px;
    border-radius: 4px;
    background-color: #E9EAEB;
    margin-right: 21px;
    margin-top: 10px;
    cursor: pointer;
}