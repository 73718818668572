.c-e-journey-sec {
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38);
    padding: 0 42px 42px 42px;
}
.c-e-journey-h {
    height: 21px;
    color: #3F4045;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    padding: 30px 0 42px 0;
}
.c-e-journey-box {
    box-sizing: border-box;
    padding: 0 21px 39px 0;
    border-radius: 4px;
    background-color: var(--whiteBg);
    cursor: pointer;
}
.c-e-journey-box-inner {
    box-sizing: border-box;
    height: 102px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #FFFFFF;
    position: relative;
}
.c-e-journey-box-inner-h {
    height: 21px;
    color: #3F4045;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: 500;
    padding: 5px 40px;
}
.c-e-journey-box-inner-tags {
    padding: 0 0 0 40px;
}
.c-e-journey-box-tag {
    border-radius: 15px;
    background-color: rgba(121,143,183,0.09);
    padding: 6px 9px;
    margin: 0 10px 0 0;
    display: inline-block;
    height: 20px;
    line-height: 2px;
}
.c-e-journey-box-tag-text {
    color: #808A8F;
    font-size: 10px;
    letter-spacing: 0;
}
.define-journey-chk {
    position: absolute !important;
}