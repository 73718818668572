.stepHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.headerFont{
    font-family: Poppins;
    font-size: 44px; 
    line-height: 62px; 
    color: #171A1FFF;
}

.subheaderFont{
    height: 16px;
    color: #808a8f;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .48px;
    line-height: 16px;
}

.sectionDiv{
    display: flex;
    flex-direction:column;
    align-items: center;
    width: 22vw;
    height: 75vh;
    background-color: white;
}

.videoSizeText{
    height: 16px;
    color: #afb8bdf5;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .48px;
    line-height: 16px;
    margin: 5vh 0vh;
}

.uplodeDiv5 {
    text-align: center;
    background-color: #efebeb;
    height: 45vh;
    width: 100%;
    border-radius: 5px;
    padding-bottom: 35px;
  }

  .buttonAlign{
    display:flex; 
    flex-direction:row; 
    justify-content:space-around;
    width:100%
  }

  .thumbnailBurron{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height:30vh;

  }

