@import url("./assets/css/roboto-font.css");
@import url("./assets/css/open-sans-font.css");
@import url("./assets/css/nunito-font.css");
@import url("./assets/css/font.css");

html,
body,
html * {
  font-family: "Roboto", sans-serif;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  background-color: #ffffff;
}

code {
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(136, 135, 135, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(75, 74, 74, 0.5);
  border-radius: 10px;
}
.MuiDialog-paperWidthSm{
  width: 390px !important;
  height: 58% !important;
}


.container01 {
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.container02 {
  display: flex;
  flex-direction: row;
}


.form-group {
  margin-right: 10px;
}

button {
  margin-left: 10px;
}

/* .MuiTextField-root{
  color:#ffffff !important;
} */

/* .MuiInputBase-input {
  color: white !important;
}

 .MuiSvgIcon-root {
  color: white;
}  */

.css-1uccc91-singleValue {
  color: #ffffff;
}