.c-e-target-h {
    height: 21px;
    color: #3F4045;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin: 30px 0 16px 6px;
}
.c-e-target-sec {
    /* border-radius: 0 0 10px 10px; */
    background-color:var(--whiteBg);
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38);
    padding: 0 42px 42px 42px;
}
.c-e-target-box-h {
    height: 21px;
    color: #FFFFFF;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
}
.c-e-target-c {
    height: 21px;
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
}
.c-e-target-per {
    height: 23px;
    color: #FFFFFF;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 23px;
}
.c-e-target-left-box-con {
    border: 2px solid #FFFFFF;
    /* box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38); */
}
.c-e-target-p-box {
    height: 255px;
    background-color: #F690CE;
    border: 2px solid #FFFFFF;
    /* box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38); */
    cursor: pointer;
    padding-left: 34.5px;
}
.c-e-target-dp-box {
    height: 125px;
    background-color: #F077A3;
    border: 2px solid #FFFFFF;
    /* box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38); */
    cursor: pointer;
    padding-top: 28px;
}
.c-e-target-g-box {
    box-sizing: border-box;
    height: 127px;
    background-color: #6aa84f;
    border: 2px solid #FFFFFF;
    /* box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38); */
    padding-top: 28px;
    cursor: pointer;
}
.c-e-target-b-box {
    box-sizing: border-box;
    height: 127px;
    background-color: #6B9CF3;
    border: 2px solid #FFFFFF;
    /* box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38); */
    padding-top: 28px;
    cursor: pointer;
}
.c-e-target-pur-box {
    height: 125px;
    background-color: #837DF0;
    border: 2px solid #FFFFFF;
    /* box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38); */
    padding-top: 28px;
    cursor: pointer;
}
.c-e-target-l-pur-box {
    height: 125px;
    background-color: #B1A2FF;
    border: 2px solid #FFFFFF;
    /* box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38); */
    padding-top: 28px;
    cursor: pointer;
}
.c-e-target-p-rule {
    height: 15px;
    color: #5F6165;
    font-size: 12px;
    font-weight: 500;
    font-family: Roboto;
    margin: 30px 0 16px 6px;
    text-transform: uppercase;
}
.c-e-target-p-rule-opt .css-yk16xz-control {
    box-sizing: border-box;
    height: 30px;
    border: 1px solid #DBDDDE;
    border-radius: 15px;
    background-color: #F2F4F5;
}
.c-e-target-p-rule-opt {
    color: #5F6165;
    font-family: "Roboto";
    font-size: 12px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
}
.c-e-t-box {
    padding-left: 37px;
}
.c-r-t-box-n {
    height: 21px;
    color: #FFFFFF;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 12px;
}
.c-r-t-box-c {
    height: 21px;
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-right: 7px;
    display: inline-block;
}
.c-r-t-box-per {
    height: 21px;
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 28px;
}
.c-r-t-box-select {
    height: 32px;
    width: 78px;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #4C7CD1;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
}
.t-a-r-5 {
    box-sizing: border-box;
    height: 30px;
    border: 1px solid #DBDDDE;
    border-radius: 15px;
    background-color: #FFFFFF;
}
#t-a-r-5 {
    opacity: 0.7;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
}
.t-a-r-5 input {
    width: 100%;
}
.css-yk16xz-control {
    /* min-height: 30px !important; */
}
.css-tlfecz-indicatorContainer {
    padding: 4px !important;
}
.p-rule-input{
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border: 1px solid #DBDDDE;
    border-radius: 14.5px;
    background-color: #FFFFFF;
    opacity: 0.7;
    color: #000000;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding-left: 2px;
    text-align: center;
}
.p-rule-value-left {
    box-sizing: border-box;
    height: 36px;
    border: 1px solid #DBDDDE;
    border-radius: 19.5px 0 0 19.5px;
    background-color: #F2F4F5;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    font-family: Roboto;
}
.p-rule-value-right {
    box-sizing: border-box;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #DBDDDE;
    border-radius: 0 19.5px 19.5px 0;
    background-color: #FFFFFF;
    font-family: Roboto;
}
.p-rule-value-txt {
    height: 36px;
    color: #808A8F;
    font-size: 14px;
    font-family: Roboto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-rule-duration {
    height: 36px;
    font-family: Roboto;
    box-sizing: border-box;
    border: 1px solid #DBDDDE;
    border-radius: 14.5px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.disable-purchase-rule{
    opacity: 0.4;
    pointer-events: none;
}
.dropdown{
    z-index: 999 !important;
    position: fixed;
}
