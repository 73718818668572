.header-container {
  display: flex;
  width: 100%;
  height: 60px;

  align-items: center;
  flex-shrink: 0;
  border: 1px solid #27272f;
  background: #27272f;
  box-shadow: 0px 2.8586230278015137px 32.90999984741211px 0px
    rgba(30, 30, 37, 0.7);
  color: #ffffff;
  justify-content: space-between;
  font-size: 13px;
}
.logo_div {
  display: flex;
  width: 80px;
  height: 60px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.img_design {
  width: 24px;
  height: 14px;
  object-fit: cover;
  border-radius: 50%;
}
.back_button {
  display: flex;
  width: 34px;
  height: 34px;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  gap: 44px;
  border-radius: 10px;
  border: 1px solid #484851;
  margin: 15px;
  cursor: pointer;
}
.rotate_button_left {
  display: flex;
  width: 34px;
  height: 34px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 44px;
  border-radius: 10px 0px 0px 10px;
  border: 1px solid #484851;
  opacity: 0.5;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.rotate_button_right {
  display: flex;
  width: 34px;
  height: 34px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 44px;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid #484851;
  background: rgba(72, 72, 81, 0.2);
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.insta_button {

  border-radius: 10px;
  border: 1px solid #484851;
  margin: 20px;
  border-radius: 10px;
  border: 1px solid #484851;
  padding: 4px;
  cursor: pointer;
}
.credit_button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #484851;
  padding: 4px 10px 4px 4px;
  cursor: pointer;
}
.popover_button {

  border-radius: 10px;
  border: 1px solid #484851;
  padding: 4px;
  cursor: pointer;
  margin: 10px;
}

.publish_button{
  border-radius: 10px;
  border: 1px solid #484851;
  border-radius: 10px;
  border: 1px solid #484851;
  background-color: "#017EFA";
  padding: 4px;
  cursor: pointer;
}


.text_conatiner_1 {
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  border-radius: 10px;
  background: rgba(72, 72, 81, 0.2);
}

#header-container {
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2.63px 25.154px rgba(0, 0, 0, 0.05),
      inset 0px 0px 0.931631px rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(13.9745px);
    border-radius: 13.9745px;
    display: block;
    margin-top: 20px;
    width: 80%;
    float: right;
  }
  #header-container2 {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 80%;
    height: 67px;
    left: 295px;
    top: 16px;
  
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2.63px 25.154px rgba(0, 0, 0, 0.05),
      inset 0px 0px 0.931631px rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(13.9745px);
    /* Note: backdrop-filter has minimal browser support */
  
    border-radius: 13.9745px;
  }
  #header-container .w-50 {
    height: inherit;
  }
  
  .h-dd {
    height: 45px;
    width: 241.87px;
    border-radius: 6px;
    background-color: var(--headerAltBg);
  }
  .h-logged-user-sec {
    /* height: 45px; */
    /* width: 74px; */
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    /* background-color: var(--headerAltBg); */
    cursor: pointer;
    /* margin-top: 12px;
    margin-right: 20px; */
  }
  .h-logged-user-d {
    padding: 0 10px;
  }
  .h-logged-user-d1,
  .h-logged-user-d2 {
    padding: 0;
    margin: 0;
  }
  .h-logged-user-d1 {
    font-size: 14px;
    line-height: 21px;
  }
  .h-logged-user-d2 {
    font-size: 11px;
    line-height: 17px;
  }
  .h-logger-user {
    height: 25px;
    width: 25px;
    /* margin: 2px; */
    /* padding: 2px; */
  }
  .h-logger-user-options img {
    margin-bottom: 3px;
  }
  .h-logger-user-options div {
    color: #1f263e;
    font-family: "Nunito Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    list-style: none;
    padding: 9px;
    cursor: pointer;
  }
  .h-logger-user-options div:last-child {
    height: 13px;
    color: #808a8f;
    font-family: Roboto;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 0;
    padding-top: 14px;
    padding-bottom: 16px;
  }
  .h-logger-user-options-sec {
    height: 144px;
    width: 185px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.23);
  }
  .h-icons {
    /* height: 21px; */
    width: 22px;
    color: black;
    /* margin: 24px 32px 24px 0; */
    cursor: pointer;
  }
  .h-links {
    /* height: 21px; */
    color: #757575;
    background: rgba(1, 126, 250, 0.1);
    backdrop-filter: blur(69.8723px);
    border-radius: 10px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    padding: 5px 12px;
    letter-spacing: -0.2px;
    line-height: 21px;
    cursor: pointer;
    margin-right: 14px;
    /* margin: 19px 14px 19px 0; */
  }
  #header-container .MuiSelect-icon {
    color: #fff;
  }
  #header-container .MuiInput-underline:before,
  #header-container .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .h-notifications {
    height: 304px;
    width: 375px;
    border-radius: 6px;
    background-color: #ffffff;
    /* box-shadow: 0 1px 6px 0 rgba(0,0,0,0.23); */
  }
  .MuiTypography-root {
    padding: 0 !important;
  }
  .h-n-h {
    border-bottom: 1px solid #f2f4f5;
    padding: 8px 16px;
  }
  .h-n-h-lbl {
    height: 21px;
    color: #1f263e;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }
  .h-n-h-lbl-read {
    height: 13px;
    opacity: 0.5;
    color: #5f6165;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 13px;
    cursor: pointer;
  }
  .h-s-sec {
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    /* margin: 19px 14px 14px 0; */
    padding: 3px 10px;
  }
  .h-s-sec-input {
    /* height: 20px; */
    width: 70%;
    opacity: 0.6;
    color: #aeb4b7;
    font-size: 14px;
    letter-spacing: -0.22px;
    line-height: 21px;
    border: none;
  }
  .h-s-sec-img {
    color: #aeb4b7 !important;
  }
  
  .headerfuncrions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  
    padding: 0px;
    gap: 20px;
    position: absolute;
    width: 739.63px;
    height: 67px;
    right: 15px;
  }
  .sectionName {
    position: absolute;
    width: 114px;
    height: 24px;
    left: 20px;
  
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
  
    color: #4f4f4f;
  }
  
  .supportRequest {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 5px;
  
    width: 145px;
    height: 32px;
  
    background: rgba(1, 126, 250, 0.1);
    backdrop-filter: blur(69.8723px);
    /* Note: backdrop-filter has minimal browser support */
  
    border-radius: 10px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .icon-container {
    padding: 4px;
    background: rgba(1, 126, 250, 0.1);
    backdrop-filter: blur(69.8723px);
    border-radius: 8px;
  }
  .productTour {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 5px;
  
    width: 122px;
    height: 32px;
  
    background: rgba(1, 126, 250, 0.1);
    backdrop-filter: blur(69.8723px);
    /* Note: backdrop-filter has minimal browser support */
  
    border-radius: 10px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  
  .searchProject {
    width: 194px;
    height: 37px;
    display: flex;
    flex-direction: row;
    position: relative;
  }
  
  .notificationhead {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px;
    gap: 10px;
    width: 32px;
    height: 32px;
    border-radius: 10px;
    background: rgba(1, 126, 250, 0.1);
    backdrop-filter: blur(69.8723px);
    /* Note: backdrop-filter has minimal browser support */
  
    /* // Inside auto layout  */
  
    flex: none;
    order: 3;
    flex-grow: 0;
    /* box-sizing: border-box;
      padding: 15px 15px 15px 15px;
      border-radius: 2px;
      color: #fff;
      background-color: #ccc;
      box-shadow: 0 0 12px #999;
      cursor: pointer;
      font-size: 1em;
      line-height: 1.2em;
      position: relative;
      opacity: 0.9; */
  }
  
  .userProfile {
    /* Auto layout */
  
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 15px 15px 0px;
    gap: 10px;
  
    width: 166.63px;
    height: 67px;
  
    border-radius: 13.9745px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 4;
    flex-grow: 0;
  }
  
  .textClass {
    width: 100px;
    height: 18px;
  
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
  
    color: #757575;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .supportIconCont {
    width: 20px;
    height: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  .iconStylefirst {
    box-sizing: border-box;
  
    position: absolute;
    left: 8.33%;
    right: 12.5%;
    top: 4.17%;
    bottom: 1.71%;
  
    border: 1.5px solid #017efa;
  }
  
  .userProfileIner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .nameandroll {
    display: flex;
    margin: 0px 10px;
    flex-direction: column;
  }
  .profileName {
    /* position: absolute;
  width: 81px;
  height: 21px;
  left: 42px;
  top: 14.89px; */
  
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
  
    /* dark */
  
    color: #1e2029;
  }
  .rollName {
    /* position: absolute;
  width: 58px;
  height: 17px;
  left: 42px;
  top: 35.11px; */
  
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 17px;
    /* identical to box height */
  
    /* dark */
  
    color: #1e2029;
  
    opacity: 0.6;
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Styling for the pop-up content */
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: white;
  }

  .poppup_container{
    border-radius: 5px;
border: 1px solid #373743;
background: #1B1B22;
box-shadow: 0px 8.987322807312012px 12.358972549438477px 0px rgba(0, 0, 0, 0.25);

  }

  .box-first-download {
    width: 202px;
    height: 60px;
    border-radius: 23.422px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: #27272f;
    box-shadow: 0px 2.8586230278015137px 32.90999984741211px 0px
      rgba(30, 30, 37, 0.7);
    margin-left: auto;
    margin-right: auto;
    margin-top: 170px;
  }
  .center-box-download {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #27272f;
    padding-top: 50px;
    cursor: pointer;
    width: 202px;
    height: 60px;
    border-radius: 23.422px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: #27272f;
    box-shadow: 0px 2.8586230278015137px 32.90999984741211px 0px
      rgba(30, 30, 37, 0.7);
      margin-top: 50px;
    
  }
  .box_download{
    width: 202px;
    height: 60px;
  }
  
  .card_socailPlaylist {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 84px;
    height: 128.348px;
    /* You can adjust the width as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(39, 39, 47);
    max-height: 130px;
  }
  .card_socailplaylist_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    background-color: rgb(39, 39, 47);
    overflow-x: auto;
    max-height: 130px;
  }

  .search_product_button{
    border-radius: 10px;
    border: 1px solid #484851;
    border-radius: 10px;
    border: 1px solid #484851;
    background-color: "#017EFA";
    padding: 4px;
    cursor: pointer;
    color: #fff;
    width: 300px;
    align-items: center;
    text-align: center;
  }

  .thumbnail_title_input_playlist{
    width: 80%;
      margin: 2px;
      width: 310px;
      height: 36px;
      background: #202027;
      border: 1px solid #484851;
      border-radius: 10px;
      bottom: 1px;
    
      color: white;
      top:200px
  }


  .thumbnail_button_container_playlist{
    display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 15px;
  
  width: 324px;
  height: 34px;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  
  /* border: 1px solid yellow; */
  position: absolute;
  bottom: 22px;
  left: 600px;
  }
  
  .thumbnail_button_submit_playlist{
    box-sizing: border-box;
  
  /* Auto layout */
  
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 8px 20px; */
  gap: 10px;
  
  width: 87px;
  height: 34px;
  cursor: pointer;
  
  /* Style */
  
  border: 1px solid #017EFA;
  border-radius: 10px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  }
  
  .thumbnail_button_cancel_playlist{
    box-sizing: border-box;
  
  /* Auto layout */
  
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 8px 20px; */
  gap: 10px;
  
  width: 87px;
  height: 34px;
  
  /* Style */
  
  border: 1px solid #017EFA;
  border-radius: 10px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  }
  
  .thumbnail_video_icon_playlist{
    position: absolute;
    top:0px;
  width: 180px;
  height: 167px;
  align-items: center;
  left: 720px;
  top: 120px
  
  }
  
  
  
  
  
  
  
  
  
