.card-container_px {
  display: flex;
  justify-content: space-between; /* Adjust this property to change the space between images */
  align-items: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.card_px {
  width: 30%; /* Adjust the width as per your requirement */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card_img_px {
  width: 100%;
  height: auto;
  display: block;
}

.card-container_px_content {
  display: flex;
  justify-content: space-between; /* Adjust this property to change the space between images */
  align-items: center;
  gap: 10px;
  overflow-x: auto;
}

.card_px_content {
  width: 30%; /* Adjust the width as per your requirement */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card_img_px_content {
  width: 100%;
  height: auto;
  display: block;
}
.card-container_px_content_new {
  display: flex;
  justify-content: space-between; /* Adjust this property to change the space between images */
  align-items: center;
  align-items: center;
  gap: 10px;
  overflow-x: auto;
}

.card_px_content_new {
  width: 50%; /* Adjust the width as per your requirement */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card_img_px_content_new {
  width: 100%;
  height: auto;
  display: block;
}
