.frame {
  background-color: #27272f;
  border: 1px solid;
  border-color: #ffffff66;
  border-radius: 10px;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  width: 1000px;
  overflow-y: auto;
}

.frame .overlap-group {
  height: 42px;
  left: 20px;
  position: absolute;
  top: 13px;
  width: 100%;
}

.frame .div {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 7px;
  width: 682px;
}

.frame .text-wrapper {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .close-circle {
  height: 28px !important;
  position: relative !important;
  width: 28px !important;
}

.frame .rectangle-wrapper {
  align-items: center;
  background-color: #22222a;
  border: 1px solid;
  border-color: #017efa;
  border-radius: 16px;
  box-shadow: 0px 3.2px 8.8px #0000001c;
  display: flex;
  gap: 12px;
  height: 42px;
  justify-content: center;
  left: 130px;
  padding: 8px;
  position: absolute;
  top: 0;
  width: 42px;
}

.frame .rectangle {
  height: 33.6px;
  margin-bottom: -2.8px;
  margin-left: -4.8px;
  margin-right: -2.8px;
  margin-top: -4.8px;
  position: relative;
  width: 33.6px;
}

.frame .add-wrapper {
  align-items: center;
  border: 1px solid;
  border-color: var(--style);
  border-radius: 10px;
  display: flex;
  gap: 44px;
  height: 34px;
  justify-content: center;
  left: 188px;
  padding: 8px 10px;
  position: absolute;
  top: 4px;
  width: 34px;
}

.frame .select-wrapper {
  left: 240px;

  position: absolute;
  top: 2px;
}

.frame .media-icon-wrapper {
  right: 45px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 2px;
  gap: 10px;
}

.frame .social-media-post-fb {
  border: 1px solid;
  border-color: var(--style);
  border-radius: 10px;
  padding: 6px 8px;
  background-color: transparent;
  color: #fff;
}

.frame .social-media-post-ig {
  border: 1px solid;
  border-color: var(--style);
  border-radius: 10px;
  padding: 8px 10px;
  background-color: transparent;
  color: #fff;
}

.frame .no-account-connected {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  right: 45px;
}

.frame .no-account {
  /* border: 1px solid; */
  /* border-color: rgb(230 129 129 / 62%); */
  border-radius: 10px;
  padding: 8px 0px;
  background-color: transparent;
  color: #ca4848;

  display: inline-block;
  top: 1px;
}

.frame .add-account-btn {
  border: 1px solid;
  border-color: var(--style);
  border-radius: 10px;
  padding: 6px 8px;
  background-color: var(--style);
  color: #fff;
  outline: none;
}

.frame .select-wrapper .select-options {
  border: 1px solid;
  border-color: var(--style);
  border-radius: 10px;
  padding: 8px 10px;
  background-color: transparent;
  color: #fff;
}

.frame .select-wrapper .select-options option {
  color: #000;
}

.frame .add-instance {
  height: 24px !important;
  margin-bottom: -3px !important;
  margin-left: -5px !important;
  margin-right: -5px !important;
  margin-top: -3px !important;
  position: relative !important;
  width: 24px !important;
}

.frame .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  left: 20px;
  position: absolute;
  top: 79px;
  width: 95%;
}

.frame .div-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.frame .div-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
  width: 100%;
}

.frame .div-5 {
  align-items: flex-end;
  background-color: #202027;
  border: 1px solid;
  border-color: #484851;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 15px;
  overflow: hidden;
  padding: 15px;
  position: relative;
}

.frame .text-wrapper-2 {
  color: #ffffffcc;
  flex: 1;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: -1px;
  position: relative;
  background: transparent;
  border: none;
  resize: none;
  outline: none;
}

.frame .div-6 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.frame .div-7 {
  align-items: center;
  background-color: #ffffff1a;
  border: 1px solid;
  border-color: var(--style);
  border-radius: 6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
}

.frame .vector {
  height: 18.62px;
  position: relative;
  width: 12.97px;
}

.frame .text-wrapper-3 {
  color: #ffffffcc;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: -0.19px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-8 {
  align-items: center;
  background-color: #ffffff1a;
  border-radius: 6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 5px 10px;
  position: relative;
}

.frame .img {
  height: 18.62px;
  position: relative;
  width: 13.97px;
}

/* .frame .div-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
} */

.frame .div-10 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-4 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .div-wrapper {
  align-items: center;
  border: 1px solid;
  border-color: var(--style);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 34px;
  opacity: 0;
  overflow: hidden;
  padding: 8px 20px;
  position: relative;
}

.frame .text-wrapper-5 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  width: fit-content;
}

.frame .div-11 {
  align-items: flex-start;
  /* display: flex; */
  display: none;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.frame .div-12 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  position: relative;
}

.frame .text-wrapper-6 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.frame .div-13 {
  align-items: center;
  align-self: stretch;
  background-color: #202027;
  border: 1px solid;
  border-color: #484851;
  border-radius: 10px;
  display: flex;
  height: 34px;
  justify-content: space-between;
  padding: 8px 10px;
  position: relative;
  width: 100%;
  pointer-events: none;
  opacity: 0.3;
}

.frame .text-wrapper-7 {
  color: #f6f6f6;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  text-align: center;
  width: fit-content;
  background: transparent;
  border: none;
}

.frame .chevron-down {
  height: 11.84px;
  position: relative;
  width: 11.84px;
}

.frame .div-14 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #202027;
  border: 1px solid;
  border-color: #484851;
  border-radius: 10px;
  display: flex;
  height: 69px;
  justify-content: space-between;
  padding: 8px 10px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-8 {
  color: #f6f6f6;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.frame .chevron-down-2 {
  height: 11.84px;
  margin-bottom: -2905.84px;
  margin-right: -2522px;
  position: relative;
  width: 11.84px;
}

.frame .chevron-down-3 {
  height: 11.84px;
  margin-bottom: -2905.84px;
  margin-right: -2173.5px;
  position: relative;
  width: 11.84px;
}

.frame .div-15 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 15px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}
.frame .div-18 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 15px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}
.frame .div-15 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 15px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.frame .div-wrapper-2 {
  align-items: center;
  background-color: var(--style);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 34px;
  overflow: hidden;
  padding: 8px 20px;
  position: relative;
  margin-bottom: 10px;
}

.frame .text-wrapper-9 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  text-align: right;
  width: fit-content;
  cursor: pointer;
}

.frame .div-16 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.frame .div-wrapper-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-10 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .div-17 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
  height: 180px;
}

.frame .image {
  height: 100px;
  object-fit: cover;
  position: relative;
  width: 100px;
}

.frame .group {
  background-image: url(https://c.animaapp.com/xRFb8S6E/img/image-37@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 100px;
  position: relative;
  width: 100px;
}

.frame .trash-wrapper {
  align-items: center;
  background-color: #202027;
  border: 1.17px solid;
  border-color: #484851;
  border-radius: 0px 10px 0px 10px;
  display: flex;
  gap: 10px;
  height: 35px;
  justify-content: center;
  left: 65px;
  padding: 10px;
  position: relative;
  width: 35px;
}

.frame .trash-instance {
  height: 20px !important;
  margin-bottom: -2.5px !important;
  margin-left: -2.5px !important;
  margin-right: -2.5px !important;
  margin-top: -2.5px !important;
  position: relative !important;
  width: 20px !important;
}

.frame .div-wrapper-4 {
  align-items: center;
  border: 1px solid;
  border-color: var(--style);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 34px;
  overflow: hidden;
  padding: 8px 20px;
  position: relative;
}

.frame .div-9 {
  display: none;
}
.frame .div-15 {
  display: none;
}

.frame .div-9.open {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.frame .div-15.open {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.frame::-webkit-scrollbar {
  width: 5px;
}

.frame::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.frame::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

.frame::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.frame .div-14 .text_for_ai {
  background: transparent;
  border: none;
  resize: none;
  outline: none;
  color: #fff;
  width: 100%;
}
