.flex-sidebar {
  display: flex;
  width: 80px;
  height: 100% !important;
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid #27272f;
  background: #27272f;
  box-shadow: 0px 2.8586230278015137px 32.90999984741211px 0px
    rgba(30, 30, 37, 0.7);
  color: #ffffff;
}

.onebox {
  display: flex;
  width: 80px;
  padding: 5px 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  font-size: 13px;
  cursor: pointer;
  color: #828293;
}
.onebox:active {
  font-weight: bold;
  background-color: rgba(1, 126, 250, 0.5);
  color: #fff;
}

.onebox_active {
  display: flex;
  width: 80px;
  padding: 5px 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  font-size: 13px;
  cursor: pointer;
  font-weight: bold;
  background-color: rgba(1, 126, 250, 0.5);
  color: #fff;
}

.img_1 {
  width: 19px;
  height: 19px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 2px;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-container {
  margin-left: 5%;
}
.box-first_new {
  width: 900px;
  height: 100%;
  background: #27272f;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 30px;
}
.center-box_new {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #27272f;
  padding: 30px;
  width: 600px;
  height: 350px;
  margin: auto;
  margin-top: 40px;
  border-radius: 23.422px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #27272f;
}
.box_2 {
  display: flex;
  padding: 10px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 24px;
  border: 1px dashed #494b4d;
  background: rgba(53, 55, 59, 0.17);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
  height: 200px;
}
.img_clouds {
  width: 28px;
  height: 28px;
  background: rgba(53, 55, 59, 0.17);
}
.box-first-bottom_1 {
  width: 1450px;
  height: 100px;
  background: #27272f;
  margin-left: 20px;
  margin-top: 20px;
}
.box-first-bottom_2 {
  width: 1100px;
  height: 100px;
  background: #27272f;
  margin-left: 20px;
  margin-top: 20px;
}

.box-first_new.post-files-container {
  width: 1100px;
  height: 100%;
  display: flex;
  gap: 15px;
  margin-right: unset;
  justify-content: center;
  align-items: center;
}

.post-files-container .center-box_new {
  margin-top: 0;
  width: 500px;
}

.social-media-popup-modal .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(39 37 37 / 50%);
  z-index: 999;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media-popup-modal .modal-content {
  position: relative;
  width: fit-content;
}

.social-media-popup-modal .center-box_new {
  width: 640px;
  height: 450px;
}
