.story {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.storyColection {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.dflex {
  display: flex;
  margin: 5px;
}

.role-description-box {
  box-sizing: border-box;
  height: 82px;
  width: 550px;
  border: 1px solid #dbddde;
  border-radius: 6px;
  background-color: #f8f9f9;
}
.role-description {
  margin-left: 15px;
}
.date {
  padding-right: 20px;
  display: flex;
  justify-content: right;
}

.video {
  width: 100% !important;
  height: 100% !important;
  margin-right: 25px;
}
.video3 {
  width: 100% !important;
  height: 90% !important;
}

.videoContainer {
  display: flex;
  margin-right: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.workSteps {
  display: flex;
  margin-right: 60px;
}

.step {
  margin-right: 15px;
  color: gray;
}

.description {
  margin-bottom: 20px;
}
.uplodeDiv {
  text-align : center;
  /* background-color: #efebeb; */
  /* border: 1px dashed #017efa; */
  height: 100%;

  width: 90%;
  margin: 0 auto;
  padding: 14px  0px;

  border-radius: 10px;
  /* padding-bottom: 35px; */
}
.uplodeErr {
  border: 1px dotted red;
}

.uplodeimg {
  /* margin-top: 70px; */
  /* margin-bottom: 30px; */
}
.disTag {
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(139, 136, 136, 0.575);
  margin-bottom: 30px;
  padding: 20px 10px 20px 10px;
  background-color: white;
}
.fontColor {
  display: flex;
  flex-direction: row;
  align-items: self-end;
  /* justify-content: space-between; */
  padding: 0px 10px 0px 0px;
}
.storyimg {
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 225px;
  border-radius: 5px;
  border: 1px solid rgba(139, 136, 136, 0.575);
  margin: 10px;
  cursor: pointer;
}
.storyImgcont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* border: 1px solid rgba(139, 136, 136, 0.575); */
  background-color: white;
}
.storyimg2 {
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 125px;
  border-radius: 5px;
  border: 1px solid rgba(139, 136, 136, 0.575);
  margin: 10px;
}
.MuiFormControlLabel-label {
  font-size: 14px;
}

.buttomAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uplodeDiv2 {
  text-align: center;
  background-color: #efebeb;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.uplodeimg2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.disTag {
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(139, 136, 136, 0.575);
  margin-bottom: 30px;
  padding: 20px 10px 20px 10px;
  background-color: white;
}

.stickerstype {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchProduct {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px 0px 8px;
}

.preViewDiv {
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}

.cardStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.overview-heading2 {
  height: 16px;
  color: #808a8f;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: 16px;
  /* text-transform: uppercase; */
}
.library-video:hover {
  background-color: #e6f3ff;
}
