.billing-container {
    padding: 0px 20px;
    color: white;
}

.tab-active {
    background-color: rgb(1, 126, 250) !important;
}

.tab-content-container {
    padding: 10px 20px;
}

.current-subscription, .title-subscription{
    font-size: 20px;
}

.current-renewal{
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
}

.current-subscription span {
    color: rgb(1, 126, 250);
    padding-left: 5px;
    border-radius: 8px;
}

.current-renewal span {
    padding-left: 5px;
    font-weight: 500;
}

.subscription-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.current-plan-details {
    margin-top: 5px;
    background-color: #27272f;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
}

.head-plan-details {
    display: flex;
    justify-content: space-between;

    > div {
        font-weight: 500;
    }
}

.body-plan-details {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 2px solid rgb(214, 212, 212);
}

.billingdetails-container {
    padding: 18px;
    display: flex;
    flex-direction: column;
}

.current-billing-details {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
    border-bottom: 3px solid #27272f;
    padding-bottom: 18px;
}

.billingdetails-card {
    background-color: #27272f;
    width: 500px;
    height: 250px;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 0px 3px #2d2d35;
}

.MuiTablePagination-root, .MuiSvgIcon-root  {
    color: white !important;
}

.footer-plan-details {
    position: relative;
    bottom: 100px;
    width: 100%;
    background-color: #27272f;
    padding: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.quantity-input {
    display: flex;
    width: 120px;
    height: 36px;
    color: #fff;
    padding: 2px 10px;
    background-color: rgb(34, 34, 42);
    border: 1px solid rgb(72, 72, 81);
    border-radius: 10px;
    border: 1px solid #484851;
    box-shadow: none;
    line-height: 28px;
    justify-content: flex-start;
    align-items: center;
}