.overview-heading {
  /* height: 16px; */
  /* color: #808a8f; */
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: 16px;
  font-weight: 600;
  color: #4f4f4f;
  text-transform: capitalize;
}
.section-sub-heading {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.sales-overview-box-outer {
  padding: 0px 10px 10px 0;
  /* text-align: center; */
  cursor: pointer;
}
.sales-overview-box {
  height: 178px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 6%);
  border-radius: 12.32px;
  background-color: #ffffff;
}
.s-o-f-options {
  /* padding: 6px 0; */
}
.s-o-f-option {
  padding: 8px 30px 8px 8px;
  cursor: pointer;
  color: #1f263e;
  font-family: "Nunito Sans";
  font-size: 13px;
  letter-spacing: 0;
  list-style: none;
  border-bottom: solid 1px lightgray;
}
