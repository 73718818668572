:root {
  --style: rgba(1, 126, 250, 1);
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-first {
  width: 90%;
  min-height: 300px;
  border-radius: 23.422px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #27272f;
  box-shadow: 0px 2.8586230278015137px 32.90999984741211px 0px
    rgba(30, 30, 37, 0.7);
  margin-left: auto;
  margin-right: auto;
  margin-top: 170px;
}
.center-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #27272f;
  padding-top: 10px;
  cursor: pointer;
}

.text_style {
  color: #ffffff;
  text-align: center;
  padding-top: 20px;
}

.flex-item {
  margin: 0 10px;
  color: #ffffff;
  background: #373743;
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* border: 1px solid red; */
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  display: flex;
  width: 144px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23px;
  min-height: 200px;
}

.img_cloud {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

.frame {
  align-items: flex-end;
  background-color: #27272f;
  border: 1px solid;
  border-color: #ffffff66;
  border-radius: 23.42px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  position: relative;
  width: 536px;
}

.frame .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.frame .text-wrapper {
  color: #ffffff;
  font-family: "Rubik-SemiBold", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .close-circle {
  height: 28px !important;
  position: relative !important;
  width: 28px !important;
}

.frame .p {
  align-self: stretch;
  color: #ffffffcc;
  font-family: "Open Sans-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  position: relative;
}

.frame .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
}

.frame .div-wrapper {
  align-items: center;
  border: 1px solid;
  border-color: var(--style);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 34px;
  overflow: hidden;
  padding: 8px 20px;
  position: relative;
}

.frame .text-wrapper-2 {
  color: #ffffff;
  font-family: "Rubik-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  width: fit-content;
}

.frame .div-wrapper-2 {
  align-items: center;
  background-color: var(--style);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 34px;
  overflow: hidden;
  padding: 8px 10px;
  position: relative;
}
