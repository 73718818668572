.account_card_container {
  width: 100%;
}

.settings__card {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 16px;
  border: 1px solid grey;
  background: #27272f;
  gap: 16px;
  border-radius: 10px;
}

.network__accounts {
  display: grid;
  grid-template-columns: repeat(1, minmax(400px, 1fr));
  gap: 16px;
}

.account__card {
  overflow: hidden !important;
  width: 100%;
}

.network__account {
  flex-direction: row;
  justify-content: unset;
  text-align: left;
  background: #27272f;
  box-shadow: 0 1px 3px rgba(25, 22, 65, 0.14);
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 16px;
  position: relative;
}

.account__picture {
  background-color: grey;
  border: 1px solid grey;
  border-radius: 100%;
  flex: 0 0 48px;
  height: 48px;
  overflow: hidden;
  width: 48px;
}

.account__image {
  border: none;
  object-fit: cover;
  border-radius: 100%;
  height: 50px;
  margin-right: 15px;
  width: 50px;
}

.account__details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  width: 100%;
}

.account__name {
  color: white;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.account__status {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  justify-content: unset;
}

.account__status.is-active {
  color: #03bd4e;
}

.account__status.not-active {
  color: red;
}
