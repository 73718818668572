.c-e-r-left {
    padding: 24px 24px 15px 32px;
    border-right: 1px solid #EDEEF0;
}
.c-e-r-left-h {
    height: 19px;
    color: #808A8F;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
}
.c-e-r-left-c-n {
    height: 26px;
    color: #3F4045;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 23px;
}
.c-e-r-left-e-txt {
    color: #808A8F;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: normal;
    padding-left: 7px;
}
.c-e-r-left-t-a-box {
    border-radius: 6px;
    background-color: #F2F4F5;
    margin-top: 13px;
    padding: 18px 30px 5px 30px;
    margin-bottom: 12px;
}
.c-e-r-left-t-a-box-h {
    height: 16px;
    color: #5F6165;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 9px;
}
.c-e-r-left-t-a-box-i {
    box-sizing: border-box;
    height: 31px;
    /* border: 1px solid #DBDDDE; */
    /* border-radius: 4px; */
    /* background-color: #FFFFFF; */
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: bold;
    padding: 4px;
}
.c-e-r-left-d-j-box {
    height: 94px;
    border-radius: 6px;
    background-color: #F2F4F5;
    margin-top: 8px;
    margin-bottom: 9px;
    padding: 18px 30px 26px 30px;
}
.c-e-r-left-d-j-tag {
    box-sizing: border-box;
    height: 31px;
    border: 1px solid #DBDDDE;
    border-radius: 15px;
    background-color: #FFFFFF;
    padding: 8.5px 10.5px 7.5px 10.5px;
    color: #5F6165;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 11px;
}
.c-e-r-left-r-b-box {
    display: inline-block;
    border-radius: 6px;
    background-color: #F2F4F5;
    margin-top: 8px;
    padding: 18px 30px 26px 30px;
}
.c-e-r-left-r-b-box-h {
    height: 16px;
    color: #5F6165;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 25px;
}
.c-e-r-left-r-b-box-bd {
    margin-bottom: 4px;
}
.c-e-r-left-r-b-box-bd-box {
    display: inline-block;
    box-sizing: border-box;
    height: 31px;
    width: 103px;
    border: 1px solid #DBDDDE;
    border-radius: 4px;
    background-color: #FFFFFF;
    margin-right: 2px;
    color: #5F6165;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
    padding: 0px 12px;
}
.c-e-r-left-r-b-box-bd-box-h {
    height: 16px;
    width: 103px;
    color: #5F6165;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    float: left;
    margin-right: 2px;
}
.c-e-r-left-r-b-box-bd-prefix {
    height: 15px;
    color: #AEB4B7;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
}
.c-e-r-right {
    padding: 14px;
}
.c-e-r-right-reach-box {
    height: 210px;
    border-bottom: 1px solid #EDEEF0;
}
.c-e-r-right-reach-h {
    height: 21px;
    color: #3F4045;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 21px;
    padding: 14px 0;
    border-bottom: 1px solid #EDEEF0;
}
.c-e-r-right-reach-sub {
    padding: 14px 0;
    border-bottom: 1px solid #EDEEF0;
}
.c-e-r-right-reach-sub-h {
    color: #6A6976;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}
.c-e-r-right-reach-sub-c {
    color: #808A8F;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 6px;
}
.c-e-r-right-reach-sub-c-c {
    color: #6B9CF3;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 6px;
}
.c-e-r-eng{
    padding: 0 0 8px 8px;
    font-size: 14px;
    font-weight: 500;
}
.c-e-r-tourn-date{
    padding-left: 10%;
}
