.stickers-card {
  width: 130px;
  height: 63px;
  /* You can adjust the width as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(39, 39, 47);
}

.card-img_text {
  width: 20%;
  max-height: 200px; /* Adjust the maximum height of the image */
  object-fit: cover;
  border-radius: 5px;
  background-color: rgb(39, 39, 47);
}

.stickers-store {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  background-color: rgb(39, 39, 47);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.typography-store {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  background-color: rgb(39, 39, 47);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #23232b;
}
