.campaign-box-outer {
    width: 33.3%;
    padding: 0 10px 0 0;
}
.campaign-box {
    border-radius: 10px;
    height: 240px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38);
    position: relative;
}
.e-s-heading {
    height: 16px;
    color: #9191AF;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}
.c-b-discount {
    height: 41px;
    border-radius: 10px 10px 0 0;
    background-color: var(--buttonBg);
}
.c-b-discount-live { background-color: var(--buttonBg); }
.c-b-discount-paused { background-color: var(--campaignPaused); }
.c-b-discount-expired { background-color: var(--campaignExpired); }
.c-b-discount-upcoming { background-color: var(--whiteBg); color: #3F4045; border-bottom: 0.5px solid #8097B1 }
.c-b-offer {
    height: 24px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}
.c-b-offer-upcoming {
    color: #3F4045;
}
.c-b-t-head {
    height: 26px;
    color: var(--buttonBg);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
}
.c-b-t-body {
    height: 18px;
    color: #6A6976;
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.28px;
    line-height: 18px;
}
.c-b-dotted {
    box-sizing: border-box;
    height: 1px;
    border: 0.5px dashed #8097B1;
    opacity: 0.76;
    position: absolute;
    bottom: 44px;
    width: 100%;
}
.c-b-footer {
    height: 44px;
    position: absolute;
    bottom: 0;
}
.c-b-lbl-expiry {
    height: 19px;
    color: #6A6976;
    font-size: 12px;
    font-family: Roboto;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
}
.c-b-campaign-options {
    width: 128px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 1px rgba(128,138,143,0.55);
}
.c-b-campaign-options div {
    color: #5F6165;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    padding: 6px 12px;
    cursor: pointer;
}
.c-b-campaign-options div:hover {
    background-color: #EDEEF0;
}
.c-b-campaign-options span {
    display: flex;
    padding: 4px 12px;
    cursor: pointer;
    font-size: 12px;
}
.c-b-campaign-options span:hover {
    background-color: rgb(226 226 226);
}
.c-b-status {
    height: 19px;
    color: #6A6976;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    text-transform: capitalize;
}
.logo-live { background-color: var(--campaignLive); height: 10px; width: 10px; border-radius: 2px; display: inline-block; }
.logo-paused { background-color: var(--campaignPaused); height: 10px; width: 10px; border-radius: 2px; display: inline-block; }
.logo-expired { background-color: var(--campaignExpired); height: 10px; width: 10px; border-radius: 2px; display: inline-block; }
.logo-upcoming { background-color: var(--buttonBg); height: 10px; width: 10px; border-radius: 2px; display: inline-block; }
.c-b-ribbon {
    background-color: var(--campaignLive);
}
.c-b-ribbon-inner {
    width: 14px;
    padding: 3px 0;
    position: absolute;
    right: 7px;
    text-align: center;
    background: var(--campaignLive);
}
.c-b-ribbon-inner:before {
    height: 0;
    width: 0;
}
.c-b-ribbon-inner:before, .c-b-ribbon-inner:after {
    content: "";
    position: absolute;
}
.c-b-ribbon-inner:after {
    height: 0;
    width: 0;
    bottom: -6.5px;
    left: 0;
    border-left: 6px solid var(--campaignLive);
    border-right: 6px solid var(--campaignLive);
    border-bottom: 7px solid transparent;
}
.c-b-ribbon-txt {
    color: #000000;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
}