.PlaylistSubheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.btn-create-journey2 {
  height: 30px;
  width: 110px;
  border-radius: 4px;
  background-color: var(--buttonBg);
  cursor: pointer;
  display: inline-block;
}
.btn-create-journey3 {
  height: 30px;
  width: 140px;
  border-radius: 4px;
  background-color: var(--buttonBg);
  cursor: pointer;
  display: inline-block;
}
.playlistTytle {
  margin: 15px 0px;
  padding-left: 2px;
  width: 100%;
  height: 30px;
  background-color: white;
  align-items: center;
  justify-content: content;
}
.story {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
