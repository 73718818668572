.story{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.storyColection{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}