*{
    padding:0;
    margin:0;
}

.container{
    height:100vh;
    background-color:#ccc;
    position:relative;
}

.sidebar{
     position:fixed;
    /* background-color:#fff; */
    background-color: rgb(30, 30, 30);;
    height:101vh;
    width:120px;
    z-index:10;
    text-align:center;
    box-shadow:0px 15px 30px rgba(0,0,0,0.1);
}
.sidebar li:nth-child(1){
    padding:30px 0px;
}


.sidebar li:hover:nth-child(1){
    padding:30px 0px;
    background-color:transparent;
    color:#000;
}

.sidebar li{
    padding:12px;
    cursor:pointer;
    transition:all 0.5s;
    letter-spacing:1px;
    display:flex;
    flex-direction:column;
}

.sidebar li span:nth-child(1){
    font-size:20px;
}

.sidebar li span:nth-child(2){
    font-size:10px;
}

.sidebar li:hover{
    background-color:#f2f2f2;
    padding:12px;
     
}

.container .content{
    
    padding-left:75px;
}



.uploadOuter {
    text-align: center;
    padding: 20px;
  }
  .dragBox {
    width: 100%;
    height: 200px;
    /* margin: 0 auto; */
    /* position: relative; */
    /* text-align: center; */
    font-weight: bold;
    line-height: 95px;
    color: #999;
    border: 2px dashed #ccc;
    /* display: inline-block; */
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  .draging {
    transform: scale(1.1);
  }
  #preview {
    text-align: center;
    
  }

  .loader {
    border: 2px solid #D3D3D3; /* Light grey */
    border-top: 2px solid white; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  } 
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  