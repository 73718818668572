.designContainer {
  height: 100% !important;
}

.flex-container {
  display: flex;
  width: 100%;
  height: 100% !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #27272f;
  box-shadow: 0px 2.8586230278015137px 32.90999984741211px 0px
    rgba(30, 30, 37, 0.7);
  color: #ffffff;
}

#homes-container {
  margin-top: -7px;
}
#homes-content {
  overflow-y: auto;
}

.searchbox {
  display: flex;
  height: 36px;
  color: #fff;
  padding: 2px 10px;
  background-color: rgb(34, 34, 42);
  border: 1px solid rgb(72, 72, 81);
  border-radius: 10px;
  border: 1px solid #484851;
  box-shadow: none;
  line-height: 28px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.search-input {
  width: 100%;
  height: 34px;
  color: #fff;
  padding: 2px 10px;
  background-color: rgb(34, 34, 42);
  border: none;
  border-color: #484851;
  box-shadow: none;
  line-height: 28px;
}

::placeholder {
  color:  #C0C0D6;
  opacity: 1;
}

::-ms-input-placeholder {
  color:  #C0C0D6;
}