.plans-maincontainer {
    margin-top: 10px;
    margin-bottom: 150px;
}

.plans-titlecontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-icon {
    background-color: rgba(221, 220, 220);
    border-radius: 5px;
}

.plans-content {
    background-color: #27272f;
}

.plans-headcontainer {
    margin-top: 5px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.interval-details {
    margin-top: 5px;
    border: 2px solid rgba(255, 255, 255, 0.7);
    border-radius: 30px;
}

.interval-details:hover {
    background-color: rgba(255, 255, 255, 0.7);
}

.interval-button {
    border-color: rgb(0,0,0,0) !important;
    border-radius: 30px !important;
    box-shadow: none !important;
    padding: 5px 25px !important;

    &.tab-active {
        border-color: rgb(0,0,0,0) !important;
        border-radius: 30px !important;
    }
}

.interval-button:hover {
    background-color: rgb(0,0,0,0) !important;
}

.tab-active:hover {
    background-color: rgb(1, 126, 250) !important;
}

.plans-bodycontainer {
    margin-top: 5px;
    align-items: center;
    justify-content: center;
}

.plans-listcontainer {
    display: flex;
    padding: 30px;
    justify-content: space-around;
    flex-wrap: wrap;
}

.planwrapper {
    display: flex;
    width: 17%;
    height: 300px;
    flex-direction: column;
    padding: 20px 10px;
    border-top: 12px solid rgb(1, 126, 250);
    border-radius: 8px;
    box-shadow: 0px 0px 3px darkgrey;
    align-items: center;
}

.plan-info {
    font-size: 18px;
    font-weight: 500;
}

.plan-addinfo {
    font-size: 14px;
    font-weight: 500;
}
