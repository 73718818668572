#verify-container{
    display: flex;
    justify-content: center;
    height: 100%;
    background-image: url(../../assets/img/curve_web.png);
    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: bottom;
    position: relative;
}

.dont-have-account{
    position: absolute;
    right: 49px;
    color: #3F4045;
    font-size: 14px;
    letter-spacing: -0.12px;
    top: 32px;
}

.l-page-navigator{
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 2px;
}