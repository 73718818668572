
input:focus,select:focus{
    outline: #E3E7ED;
}


.reward-options-tab{
    margin-top: 10px;
}
.r-m-tab {
    height: 18px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.46px;
    line-height: 18px;
    padding: 2px 20px;
    display: inline-block;
    cursor: pointer;
}
.r-m-tab-active {
    height: 30px;
    border-radius: 20px;
    background-color: rgba(107,156,243,0.09);
    color: var(--buttonBg);
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
}
.c-r-search-box{
    box-sizing: border-box;
  height: 30px;
  width: 174px;
  border: 1px solid #DBDDDE;
  border-radius: 14.5px;
  background-color: #FFFFFF;
}




.disp-inline-block{
    display: inline-block;
}


.manage-coupons{
    height: 21px;
    margin-bottom: 25px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
}

.create-new-rewards{
    height: 500px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 40px;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38);
}

.add-coupon{
    height: 21px;
    color: #6B9CF3;
    margin-bottom: 25px;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
}

.display-name{
    height: 62px;
    /* width: 556px; */
    display: inline-block;
    
}
.display-name-text{
    height: 17px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}

.c-r-display-input-field{
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
    padding-left: 10px;
    font-weight: 500;
}

.c-r-coupon-code{
    height: 62px;
    display: inline-block;
}

.c-r-coupon-code-text{
    height: 17px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
}

.reward_info_header{
    height: 17px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    margin-top: 15px;
}

.coupon-code-input-field{
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
    padding-left: 10px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
}

.c-r-row-2{
    height: 62px;
  /* width: 886px; */
  margin-top: 18px;
}
.target-category-text{
    height: 17px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
}
.c-r-row-3{
    margin: 17px auto;
}
.usage-per-customer,.reward-amount,.amount-selection-box,.expiry-date{
    margin-right: 18px;
}
.usage-per-customer-text{
    height: 17px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}
.usage-per-customer-input-field{
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
    padding-left: 10px;
    font-weight: 500;
}

::-webkit-input-placeholder{
    font-family: Roboto;
    font-size: 14px;
    opacity: 0.4;
    font-weight: normal;
}
.rewarded-amount{
    height: 17px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    color: #000000;
}

.prize-types{
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
}
.option-text{
    padding-left: 10px;
    height: 17px;
    width: 87px;
    font-family: Roboto;
    font-size: 14px;
}
.input-tags-text{
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
}

.currency-selector{
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
}

.amount-input{
    box-sizing: border-box;
  height: 40px;
  border: 1px solid #E3E7ED;
  border-radius: 4px;
  background-color: #F8F9F9;
  padding-left: 10px;
}

.expiry-date-text{
    height: 17px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

.expiry-date-input{
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
}

.description-box{
    margin-top: 18px;
    margin-bottom: 20px;
}
.description-text{
    height: 16px;
    color: #5F6165;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}
.description-input{
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
}

.description-input:focus{
    border: 1px solid #E3E7ED;
}

.c-r-controls{
    height: 60px;
    box-sizing: border-box;
    /* border-bottom: 1px solid #EDEEF0; */
}
.c-r-control-buttons{
    height: 60px;
    margin-top: 40px;
}
.c-r-cancel-button{
    height: 38px;
    width: 89px;
    border-radius: 4px;
    
}
.c-r-add-button{
    height: 38px;
    width: 89px;
    border-radius: 4px;
    background-color: #4ACB91;
}

.cancel-btn-font{
    height: 20px;
  width: 49px;
  padding: 9px 30px;
  color: #808A8F;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.add-btn-font{
    height: 20px;
  width: 29px;
  padding: 9px 30px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.c-d-p-reward-container{
    box-sizing: border-box;
    height: 40px;
    width: 285px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
}
.create-new-rewards .c-d-p-container {
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
    padding: 7.5px 0 7.5px 10px;
}
.create-new-rewards input[type="text"] {
    padding-left: 13px;
}
.reward-description-box{
    box-sizing: border-box;
    height: 65px;
    width: 600px;
    border: 1px solid #DBDDDE;
    border-radius: 6px;
    background-color: #F8F9F9;
  }
.reward-description{
    padding-top: 15px;
}