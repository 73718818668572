.subHeaderMv12{
    display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px 15px;
gap: 10px;
width: 100%;
height: 37px;

}

.videoLibrarylable{

    width: 107px;
    height: 24px;
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    
    
    color: #4F4F4F;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.contentroot{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.videocollectiondiv{
width: 75%;
height: 80vh;
/* //background: red; */
overflow-y: scroll;
}

.previeoDiv{   
width: 23%;
height: 80vh;
background: rgb(0, 255, 191);
}
.videoCard{
width: 259px;
height: 274.39px;
background: rgb(0, 255, 191);
}
.cardLayout{
    

width: 259px;
height: 274.39px;
background: #E6F3FF;
box-shadow: 0px 2.56436px 15.3861px rgba(0, 0, 0, 0.08);
border-radius: 19.2327px;
transform: matrix(-1, 0, 0, 1, 0, 0);
padding-top: 12.8px;
}

.videoPlayerDiv{
 position: relative;  
width: 233.61px;
height: 174.38px;
margin: 0px 12.8px;
background: rgba(0, 0, 0, 0.1);
border-radius: 12.8218px;
transform: matrix(-1, 0, 0, 1, 0, 0);
}
.optionmenudiv{
    display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 0px;
gap: 14px;
position: absolute;
width: 28.21px;
height: 28.21px;
right: 5px;
top: 6px;
background: rgba(0, 0, 0, 0.4);
backdrop-filter: blur(2.56436px);
border-radius: 8.29645px;
flex: none;
order: 0;
flex-grow: 0;
}


.videodurationdiv{
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 2.56436px 12.8218px;
gap: 12.82px;

position: absolute;
width: 56.42px;
height: 24.36px;
right: 6px;
bottom: 6px;

background: rgba(1, 126, 250, 0.4);
backdrop-filter: blur(2.56436px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 12.8218px;
}

.videoDurationtypo{
    
width: 31px;
height: 19px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 12.8218px;
line-height: 19px;

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.videoTytle34{
    width: 178px;
    height: 21px;
    margin:12.8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14.104px;
    line-height: 21px;
    
    
    color: #4F4F4F;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}


