.assets_container {
  display: inline-flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.box_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  width: 300px;
  height: 610px;
  border-radius: 15px;
  background: #27272f;
  border: 1px solid #27272f;
  margin-top: 10px;
  margin-left: 20px;
}
.box_22 {
  display: flex;
  padding: 10px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 24px;
  border: 1px dashed #494b4d;
  background: rgba(53, 55, 59, 0.17);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
  height: 180px;
  margin-top: 10px;
}

.text_upload {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
}

.assets_btn {
  border-radius: 10px;
  border-bottom: 1px solid var(--style, #017efa);
  background: #373743;
}

.upload-product {
  display: flex;
  width: 270px;
  padding: 10px;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 1px dashed #494B4D;
  background: rgba(27, 27, 34, 0.40);
}
