.video_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 15px;
  gap: 10px;
  width: 258.25px;
  height: 219px;
  max-width: 258.25px;
  max-height: 219px;
  background: #27272f;
  border: 1px solid #017efa;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  flex: none;
  order: 0;
  flex-grow: 1;
  position: relative;
}

.video_container01 {
  /* object-fit: cover; */
  width: 238px;
  height: 150px;
  top: 10px;
  left: 248.13px;
  border-radius: 15px;
  border: 1px solid #ffffff;
  box-shadow: 0px 2.56px 15.39px 0px rgba(0, 0, 0, 0.05);
}

.play_icon {
  position: absolute;
  width: 21.69px;
  height: 21px;
  left: calc(50% - 21.69px / 2 - 0.15px);
  top: calc(50% - 21px / 2 - 24px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.play_icon_img {
  width: 21.69px;
  height: 21px;
  object-fit: contain;
}

.size_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  gap: 10px;
  position: absolute;
  width: 53.72px;
  height: 23px;
  bottom: 60px;
  /* bottom: -100px; */
  left: 14px;
  background: #35373b;
  border-radius: 0px 10px;
  z-index: 2;
}

.size_container_text {
  width: 38px;
  height: 13px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  position: relative;
  /* top: 5px; */
  left: 0px;
}

.timestamp_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  position: absolute;
  width: 56.72px;
  height: 23px;
  left: 187px;
  bottom: 60px;
  background: #35373b;
  border-radius: 10px 0px;
  z-index: 2;
}

.timestamp_container_text {
  width: 42px;
  height: 13px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.generatebox_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  width: 300px;
  height: 34px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  /* border: 1px solid green; */ /* to test */
}

.checkbox_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  width: 238.25px;
  height: 34px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  /* border: 1px solid green; */ /* to test */
}

.checkbox_container_unselected {
  width: 19px;
  height: 19px;
  /* flex: none; */
  display: flex;
  box-sizing: border-box;
  border-radius: 5px;
  order: 0;
  flex-grow: 0;
  border: 3px solid rgba(255, 255, 255, 0.5);
  top: 2px;
  left: 3px;
  position: relative;
}

.checkbox_container_selected {
  width: 19px;
  height: 19px;
  /* flex: none; */
  display: flex;
  box-sizing: border-box;
  border-radius: 5px;
  order: 0;
  flex-grow: 0;
  border: 3px solid rgba(255, 255, 255, 1);
  top: 2px;
  left: 3px;
  position: relative;
}

.checkbox_container_selected_true {
  position: relative;
  /* top: 1px; */
  width: 10px;
  height: 10px;
  left: 2.5px;
  top: 2px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid rgb(3, 24, 118);
  /* box-shadow: 2px 2px 2px 2px rgb(3, 154, 255); */
  /* see this one time */
}

.ig_title {
  width: 189.25px;
  /* height: 34px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 1;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.page_no_navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 15px;
  position: absolute;
  /* width: 70%; */
  height: 36px;
  left: 780px;
  top: 113px;
  background: #22222a;
}
@media (max-width: 1440px) {
  .page_no_navbar {
    left: 700px;
  }
}

.page_no_navbar_polygon {
  width: 36px;
  height: 36px;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.page_no_navbar_polygon_01 {
  /* position: absolute; */
  width: 36px;
  height: 36px;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
  /* border: 1px solid white; */
}

.page_no_navbar_polygon_text {
  /* position: absolute; */
  width: 7px;
  height: 24px;
  padding-left: calc(50% - 7px / 2 + 0.5px);
  padding-top: calc(50% - 24px / 2);
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.page_no_navbar_line_01 {
  width: 93px;
  height: 5.5px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.page_no_navbar_line_01_bright {
  position: absolute;
  width: 93px;
  height: 5px;
  left: 51px;
  top: 15.75px;
  background: #ffffff;
  border-radius: 40px;
}

.page_no_navbar_line_01_dim {
  position: absolute;
  width: 93px;
  height: 5px;
  left: 51px;
  top: 15.25px;
  background: #828293;
  border-radius: 40px;
}

.page_no_navbar_polygon_2nd {
  width: 36px;
  height: 36px;
  left: 159px;
  border-radius: 10px;
  gap: 10px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.page_no_navbar_polygon_2nd_01 {
  /* position: absolute; */
  width: 36px;
  height: 36px;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
  /* border: 1px solid white; */
}

.page_no_navbar_polygon_2nd_text {
  /* position: absolute; */
  width: 7px;
  height: 24px;
  padding-left: calc(50% - 7px / 2 + 0.5px);
  padding-top: calc(50% - 24px / 2);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.page_no_navbar_line_02 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 93px;
  height: 5px;
  background: #828293;
  border-radius: 40px;
  flex: none;
  order: 3;
  flex-grow: 0;
  top: 15.5px;
  left: 210px;
}

.page_no_navbar_line_02_bright {
  position: absolute;
  width: 93px;
  height: 5px;
  left: 210px;
  top: 15.75px;
  background: #ffffff;
  border-radius: 40px;
}

.page_no_navbar_line_02_dim {
  position: absolute;
  width: 93px;
  height: 5px;
  left: 210px;
  top: 15.25px;
  background: #828293;
  border-radius: 40px;
}

.page_no_navbar_polygon_3rd {
  width: 36px;
  height: 36px;
  left: 318px;
  border-radius: 10px;
  gap: 10px;
  flex: none;
  order: 4;
  flex-grow: 0;
}

.page_no_navbar_polygon_3rd_01 {
  /* position: absolute; */
  width: 36px;
  height: 36px;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
  /* border: 1px solid white; */
}

.page_no_navbar_polygon_3rd_text {
  /* position: absolute; */
  width: 7px;
  height: 24px;
  padding-left: calc(50% - 7px / 2 + 0.5px);
  padding-top: calc(50% - 24px / 2);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.mid_navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 10px;
  position: absolute;
  /* width: 1122px; */
  width: 100px;
  height: 44px;
  left: 400px;
  top: 189px;
}

.mid_navbar_photo_video {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px;
  gap: 10px;
  width: 100px;
  height: 44px;
  background: #27272f;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  /* border: 1px solid blue; */ /* Uncomment this line to test */
}

.mid_navbar_photo {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  gap: 44px;
  width: 90px;
  height: 34px;
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.mid_navbar_photo_text {
  width: 47px;
  height: 17px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mid_navbar_video {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  gap: 44px;
  width: 90px;
  height: 34px;
  /* background: #222229; */
  /* border: 1px solid #017EFA; */
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mid_navbar_video_text {
  width: 46px;
  height: 17px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mid_navbar_duration_size {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 15px;
  width: 208px;
  height: 34px;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* border: 1px solid blue; */ /* Uncomment to test */

  position: absolute;
  right: 30px;
  color: white;
}

.select_dropdown {
  padding: 8px 12px;

  color: #83838d;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 44px;

  background: #202027;
  border: 1px solid #484851;
  border-radius: 10px;
}

.select_dropdown:focus,
.select_dropdown:hover {
  outline: none;

  border: 1px solid #bbbbbb;
}

.select_dropdown option {
  background-color: #121313;
  border: #121313;
  color: #bbbbbb;
  border-radius: 10px;
}

.mid_navbar_duration {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 25px;
  width: 111px;
  height: 34px;
  border: 1px solid #484851;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mid_navbar_duration_text {
  width: 57px;
  height: 17px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #c0c0d6;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mid_navbar_size {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 25px;
  width: 82px;
  height: 34px;
  border: 1px solid #484851;
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.mid_navbar_size_text {
  width: 28px;
  height: 17px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #c0c0d6;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.playlist_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  position: absolute;
  width: 1123px;
  height: 458px;
  left: 400px;
  top: 251px;
  overflow-y: auto;
}

.design_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 30px;
  position: absolute;
  justify-content: center;

  top: 179px;
  overflow-y: auto;
  /* border: 5px solid rgb(239, 11, 11); */
}

/* 1st container (left) */

.search_product_container {
  width: 354px;
  height: 555px;
  flex: none;
  order: 0;
  flex-grow: 1;
  /* 1 to 0 so that no grow and shrink */
  /* border: 4px solid rgb(33, 241, 33); */
  /* background: #27272F; */
}

.search_product_container00 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  gap: 20px;
  position: absolute;
  width: 354px;
  height: 555px;
  left: 0px;
  top: 0px;
  background: #27272f;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  /* border: 4px solid rgb(33, 241, 33); */
}

.search_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
  width: 324px;
  height: 59px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  /* border: 2px solid red; */
}

.search_product_text {
  width: 102px;
  height: 17px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.add_product_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
  width: 324px;
  height: 446px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  /* border: 2px solid red; */
  position: absolute;
  top: 87px;
  left: 19px;
}

.add_product_list_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
  width: 324px;
  height: 21px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.add_product_list_text {
  width: 114px;
  height: 21px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.add_product_list_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 15px;
  width: 333px;
  height: 426px;
  overflow-y: scroll;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  /* border: 2px solid green; */
  position: absolute;
  top: 22px;
}

.product_container {
  width: 324px;
  height: 84px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  /* border: 10px solid white; */
  background: #22222a;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  position: relative;
}

.product_container00 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  position: absolute;
  width: 303.03px;
  height: 64px;
  left: 10.49px;
  top: 10px;
  /* border: 10px solid white; */
}

.rectangle_img {
  box-sizing: border-box;

  width: 64px;
  height: 64px;

  /* background: url(image); */
  border: 1px solid white;
  filter: drop-shadow(0px 0px 14.4072px rgba(0, 0, 0, 0.07));
  border-radius: 12.4823px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.product_text_Container {
  width: 215px;
  height: 64px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.product_text_top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 9px;

  position: absolute;
  width: 215px;
  height: 64px;
  left: 74px;
  top: 0px;
}

.product_text_top_text {
  width: 215px;
  height: 34px;
  padding: 10px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.product_price_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 93px;
  height: 21px;
  position: absolute;

  bottom: 2px;
  left: 10px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  /* border: 1px solid white; */
}

.product_price_old {
  width: 36px;
  height: 21px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-decoration-line: line-through;

  color: #c0c0d6;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.product_price_new {
  width: 49px;
  height: 21px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.product_delete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  /* gap: 10px; */
  /* because of this svg not able to expand */

  position: absolute;
  width: 35px;
  height: 35px;
  right: 0px;
  bottom: 0px;

  background: #35373b;
  border-radius: 10px 0px;
}

/* 2nd container (middle) */

.media_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 15px;
  gap: 25px;
  width: 354px;
  height: 555px;
  background: #27272f;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 1;
  /* border: 4px solid green; */
}

.player_container {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 25px;
  isolation: isolate;

  width: 354px;
  height: 555px;

  background: #27272f;
  border: 1px solid #484851;
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  position: relative;
}

.player_left_icon {
  width: 50px;
  height: 50px;

  opacity: 0.5;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
  /* border: 2px solid red; */
  position: absolute;

  left: 20px;
  transform: scaleX(-1);
}

.player_react_player {
  /* position: absolute; */
  width: 315px;
  height: 555px;
  left: 68px;
  top: 0px;
  border: 2px solid rgb(2, 74, 190);
}

.player_right_icon {
  width: 50px;
  height: 50px;

  opacity: 0.5;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
  /* border: 2px solid red; */
  position: absolute;

  right: 20px;
}

.media_scroll_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 15px;
  gap: 10px;

  width: 354px;
  height: 14px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  border: 1px solid #484851;

  position: relative;
}

.media_text_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 324px;
  height: 17px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  position: absolute;
  top: 15px;
}

.media_text_container_text {
  width: 41px;
  height: 17px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.media_productImage_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  width: 324px;
  height: 110px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  /* border: 1px solid white; */

  overflow-x: scroll;

  position: absolute;
  top: 32px;
}

.media_productImage_container::-webkit-scrollbar {
  width: 100%;
  height: 8px;
}

.media_productImage {
  box-sizing: border-box;

  width: 100px;
  height: 100px;

  border-radius: 13.6986px;

  border: 1px solid white;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

/* 3rd container (right) */

.thumbnail_container {
  width: 354px;
  height: 555px;
  flex: none;
  order: 2;
  flex-grow: 1;
  /* border: 4px solid green; */
  /* background: #27272F; */
}

.thumbnail_container00 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 15px;
  gap: 20px;

  position: absolute;
  width: 354px;
  height: 555px;
  /* left: 768px; */
  right: 0px;
  top: 0px;

  background: #27272f;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  position: relative;
  overflow-y: auto;
}

.add_thubnail_text {
  width: 109px;
  height: 21px;

  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.thumbnail_title_text_playlist_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;

  width: 326px;
  height: 420px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  /* border: 0.5px solid white; */

  position: relative;
}

.thumbnail_video_container {
  width: 326px;
  height: 272px;

  /* border: 1px dashed rgba(1, 126, 250, 0.2); */
  filter: drop-shadow(0px 1px 22px rgba(0, 0, 0, 0.04));
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  /* align-self: stretch; */
  flex-grow: 0;

  /* border: 1px dotted blue; */

  position: absolute;
}

.thumbnail_video_icon {
  position: absolute;
  top: 0px;
  width: 180px;
  height: 167px;
  left: calc(50% - 180px / 2);
  top: calc(50% - 164.4px / 2 + 0.2px);
}

.thumbnail_title_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;

  width: 326px;
  height: 59px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  /* border: 1px solid red; */

  position: absolute;
  bottom: 60px;
}

.thumbnail_title_text {
  width: 100px;
  height: 17px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: right;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.thumbnail_title_input {
  width: 80%;
  margin: 2px;
  width: 310px;
  height: 36px;
  background: #202027;
  border: 1px solid #484851;
  border-radius: 10px;
  bottom: 1px;
  position: absolute;
  color: white;
}
/* 
.thumbnail_playlist_container{
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 5px;

width: 326px;
height: 59px;




flex: none;
order: 1;
flex-grow: 0;

border: 1px solid red;

position:relative;

bottom: -350px;
} */

.thumbnail_playlist_text {
  width: 47px;
  height: 17px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: right;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  position: absolute;
  bottom: 40px;
}

.add_New_Button_container {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 8px 20px; */
  gap: 10px;

  width: 100px;
  height: 37px;

  /* Style */

  border: 1px solid #017efa;
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  position: absolute;
  bottom: 5px;
  right: 0px;
}

/* .custom-autocomplete-paper {
  max-height: 37px !important;
} */

.MuiAutocomplete-tag {
  margin: 0px !important;
  max-width: calc(100% - 6px);
  position: absolute;
  background-color: rgb(51, 49, 49) !important;
  color: #d4d4de !important;
}
.MuiAutocomplete-input {
  margin: 0px !important;
  max-width: calc(100% - 6px);
  position: absolute;
}

#combo-box-demo {
  width: 196px;
}

.custom-autocomplete-inputRoot {
  height: 37px !important;
}

.custom-autocomplete-input {
  height: 37px !important;
}

.product_playlist_dropdown {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 44px;

  width: 216px;
  height: 37px;

  background: #202027;
  border: 1px solid #484851;
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}
/* submit and cancel   */
.thumbnail_button_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 15px;

  width: 324px;
  height: 34px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;

  /* border: 1px solid yellow; */
  /* position: absolute;
  bottom: 140px;
  left: 60%; */
}

.thumbnail_button_submit {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 8px 20px; */
  gap: 10px;

  width: 87px;
  height: 34px;
  cursor: pointer;

  /* Style */

  border: 1px solid #017efa;
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.thumbnail_button_cancel {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 8px 20px; */
  gap: 10px;

  width: 87px;
  height: 34px;

  /* Style */

  border: 1px solid #017efa;
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.select_platform_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 10px;

  position: absolute;
  width: 480px;
  height: 48px;
  left: 700px;
  top: 189px;

  /* border:1px solid green; */
  background: #22222a;
}
@media (max-width: 1440px) {
  .select_platform_text_container {
    left: 650px;
  }
}

@media (max-width: 1440) {
  .design_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 30px;
    position: absolute;
    width: 900px;
    height: 350px;
    left: 310px;
    top: 179px;
    overflow-y: auto;
    /* border: 5px solid rgb(239, 11, 11); */
  }
}

.select_platform_text_00 {
  width: 295px;
  height: 21px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: rgba(255, 255, 255, 0.8);
  /* color: blue; */

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.select_platform_text_01 {
  width: 480px;
  height: 17px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: rgba(255, 255, 255, 0.5);
  /* color: blue; */

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

@media (max-width: 1440px) {
  .playlist_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0px;
    gap: 30px;
    position: absolute;
    width: 900px;
    height: 458px;
    left: 400px;
    top: 251px;
    overflow-y: auto;
  }
}
.platforms_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;

  position: absolute;
  width: 1122px;
  height: 76px;
  left: 400px;
  top: 277px;
}
@media (max-width: 1440px) {
  .platforms_container {
    left: 350px;
  }
}

.instagram_container {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;

  width: 300px;
  height: 76px;

  background: #414145;
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* border: 1px solid red; */
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  /* align-self: stretch; */
  /* flex-grow: 1; */
}

@media (max-width: 1440px) {
  .instagram_container {
    width: 320px;
  }
}

.insta_icon {
  width: 48px;
  height: 48px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.insta_text_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;

  width: 230px;
  height: 39px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.insta_text_01 {
  width: 230px;
  height: 17px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.insta_text_02 {
  width: 230px;
  height: 17px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height, or 121% */

  color: rgba(255, 255, 255, 0.8);

  /* Inside auto layout */
  text-wrap: wrap;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.tiktok_container {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;

  width: 300px;
  height: 76px;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  background: #22222a;

  /* Inside auto layout */

  flex: none;
  /* order: 1; */
  /* flex-grow: 1; */
}

@media (max-width: 1440px) {
  .tiktok_container {
    width: 320px;
  }
}

.tiktok_icon {
  width: 48px;
  height: 48px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.tiktok_text_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;

  width: 230px;
  height: 56px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.tiktok_text_01 {
  width: 230px;
  height: 17px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.tiktok_text_02 {
  width: 230px;
  height: 34px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* or 121% */

  color: rgba(255, 255, 255, 0.8);

  /* Inside auto layout */
  text-wrap: wrap;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.youtube_container {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;

  width: 300px;
  height: 76px;

  /* opacity: 0.7; */
  background: #414145;
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* border: 1px solid red; */
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  /* order: 2;
flex-grow: 1; */
  /* background: #22222a; */
}
@media (max-width: 1440px) {
  .youtube_container {
    width: 320px;
  }
}

.youtube_icon {
  width: 48px;
  height: 48px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.youtube_text_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;

  width: 230px;
  height: 56px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.youtube_text_01 {
  width: 230px;
  height: 17px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.youtube_text_02 {
  width: 230px;
  height: 17px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* or 121% */

  color: rgba(255, 255, 255, 0.8);

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

/* .MuiDialog-paper{
  background-color: blueviolet;
}

.MuiPaper-root {
  color: rgb(255 255 255 / 87%)!important;
  background-color: #161414 !important;
}

#alert-dialog-slide-description{
  color: rgb(255 255 255 / 87%)!important;
} */

/* .MuiTextField-root{
  color:#ffffff !important;
}

.MuiInputBase-input {
  color: white !important;
}

.MuiSvgIcon-root {
  color: white;
} */