.manage-journey-block{
    padding-bottom: 15px;
    display: inline-block;
}

.manage-journey{
    height: 21px;
    color: #3F4045;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
}

.manage-journey-text{
    height: 16px;
    color: #6A6976;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}

.journey-table-block{
    margin: 2px;
}

.btn-create-journey {
    height: 38px;
    width: 200px;
    border-radius: 4px;
    background-color: var(--buttonBg);
    cursor: pointer;
    display: inline-block;
}

.btn-c-j-text {
    height: 20px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}

.create-new-journey-container{
    height: 750px;
    /* border-radius: 10px; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38);
    padding: 20px 42px;
}

.heading-c-j{
    height: 21px;
    color: #3F4045;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 17px;
} 

.journey-name-c-j{
    opacity: 0.5;
    color: #5F6165;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    padding-bottom: 10px;

}

.input-field-c-j{
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
    padding: 12.5px 14.5px;
}

.input-field-c-j:hover{
    border: 1px solid #E3E7ED;
    border-radius: 4px;
}
.c-j-lbl-u-t {
    height: 15px;
    opacity: 0.5;
    color: #5F6165;
    /* font-family: Lato; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    margin: 10px 0;
}
.dragable-tasks {
    margin-bottom: 39px;
}
.dropped-tasks {
    margin-top: 21px;
}
.dd-h {
    height: 17px;
    color: #6B9CF3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
}
.u-t-box {
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #DBDDDE;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.17);
    padding: 7px 11px;
    margin-bottom: 10px;
    margin-right: 5px;
    cursor: move;
}
.u-t-box-h {
    height: 17px;
    color: #5F6165;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
}
.u-t-box img { 
    float: right;
    padding: 5px 0;
}
.u-a-t-drop-template {
    box-sizing: border-box;
    height: 75px;
    border: 1px dashed #CED3D6;
    border-radius: 4px;
    background-color: #F8F9F9;
    margin-bottom: 14px;
    padding-top: 20px;
}
.u-t-dropped-box {
    box-sizing: border-box;
    height: 125px;
    border: 1px solid #6B9CF3;
    border-radius: 4px;
    background-color: rgba(107,156,243,0.1);
    padding: 9px;
    margin-bottom: 14px;
}
.u-t-dropped-box-h {
    height: 39px;
    border-radius: 4px;
    background-color: #6B9CF3;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.17);
    margin-bottom: 13px;
}
.u-t-dropped-box-h-lbl {
    height: 17px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    padding: 11px;
}
.u-t-dropped-box-sub-h {
    height: 12px;
    color: #5F6165;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 5px;
}

.u-a-t-drop-template-add {
    height: 15px;
    color: #5F6165;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
}
.u-a-t-drop-template-desc {
    height: 12px;
    color: #AEB4B7;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
}

.c-j-action-sec{
    height: 62px;     
}

/* came from master */

.c-j-a {
    height: 62px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38);
    display: flex;
    align-items: center;
}
.c-j-a-s {
    height: 38px;
    width: 89px;
    border-radius: 4px;
    background-color: #4ACB91;
    color: #ffffff;
    cursor: pointer;
}
.c-j-a-c {
    height: 38px;
    width: 89px;
    border-radius: 4px;
    cursor: pointer;
    color: #808A8F;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
}




.m-s-heading {
    height: 21px;
    color: #3F4045;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
}
.m-s-sub-heading {
    height: 16px;
    color: #6A6976;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}
.s-create {
    padding: 20px 44px;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38);
    margin-top: 24px;
}
.s-c-h {
    height: 21px;
    color: #3F4045;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 26px;
}
.s-c-n-h {
    height: 16px;
    opacity: 0.5;
    color: #5F6165;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 6px;
}
.s-c-n-input {
    box-sizing: border-box;
    height: 40px;
    width: 441px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F8F9F9;
    padding: 2px 6px;
}
.s-c-n-input input {
    width: 100%;
    border: none;
    height: 100%;
    background-color: #F8F9F9;
}
.s-c-name {
    margin-bottom: 28.5px;
}
.s-c-t-h {
    height: 15px;
    opacity: 0.5;
    color: #5F6165;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 8px;
}
.s-c-t-box {
    box-sizing: border-box;
    height: 63px;
    width: 20%;
    border: 1px solid #6B9CF3;
    border-radius: 4px;
    background-color: #FAFCFF;
    margin-right: 17.5px;
    cursor: pointer;
}
.s-c-t-box-txt {
    height: 21px;
    color: #3F4045;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
}
.s-c-t-box-count {
    height: 21px;
    color: #6B9CF3;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
}
.s-c-t-sec {
    margin-bottom: 30px;
}
.s-c-f-h {
    margin-bottom: 18px;
}
.s-c-f-h-txt {
    height: 15px;
    color: #5F6165;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
}
.s-c-f-add {
    cursor: pointer;
}
.s-c-f-sec {
    margin-bottom: 40px;
}
.s-c-ag-h {
    margin-bottom: 19px;
}
.s-c-ag-h-img {
    width: 18px;
}
.s-c-ag-h-txt {
    height: 15px;
    color: #5F6165;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
}
.s-c-ag-box {
    box-sizing: border-box;
    height: 43px;
    width: 30%;
    border: 1px solid #6B9CF3;
    border-radius: 4px;
    background-color: #FAFCFF;
    margin-right: 17.5px;
    cursor: pointer;
}
.s-c-ag-box-txt {
    height: 21px;
    color: #3F4045;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
}
.s-c-ag-sec {
    margin-bottom: 28px;
}
.s-c-l-sec {
    margin-bottom: 28px;
}
.s-c-l-h {
    margin-bottom: 28px;
}
.s-c-l-h-txt {
    height: 15px;
    color: #5F6165;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
}
.s-c-l-s {
    margin-bottom: 24px;
}
.s-c-l-s-h {
    height: 16px;
    color: #5F6165;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 6.5px;
}
.s-c-l-s-sec {
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #FFFFFF;
}
.s-c-l-s-chk-lbl {
    height: 21px;
    color: #3F4045;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: 500;
}
.s-create-a {
    height: 112px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(95,100,112,0.38);
    display: flex;
    align-items: center;
}
.s-create-a-s {
    height: 38px;
    width: 89px;
    border-radius: 4px;
    background-color: #4ACB91;
    color: #ffffff;
    cursor: pointer;
}
.s-create-a-c {
    height: 38px;
    width: 89px;
    border-radius: 4px;
    cursor: pointer;
    color: #808A8F;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
}
.s-c-l-m {
    margin: 30px 0 30px 0;
}