.closeBtn {
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 50%;
    top: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    right: 30px;
    align-items: center;
}

.payment-container {
    padding: 20px;
    background-color: #27272f;
    border-radius: 15px;
    height: max-content;
    width: 40%;
}

.slider-maincontainer {
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.input-suffix {
    display: flex;
    flex-direction: column;
    grid-gap: 0px;
}

.total-container {
    width: 40%;
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 15px;
    background-color: rgb(34, 34, 42);   
}

.total-details {
    margin: 0px;
    height: 26px;
    display: flex;
    justify-content: space-between;

    > p {
        font-size: 14px;
        margin: 0px;
    }
}
